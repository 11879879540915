// todo use ga package

const hasGA = () => typeof ga !== 'undefined';

export default function trackGA(params) {
  if (__DEV__) {
    console.log(`GA send %c${params.eventLabel || 'trackGA'}`, 'font-weight: bold', params);
  } else {
    hasGA() && params && ga('send', params);
  }
}

export function setGA(key, value) {
  if (__DEV__) {
    console.log(`GA set %c${key}`, 'font-weight: bold', value);
  } else {
    hasGA() && key && value && ga('set', key, value);
  }
}
