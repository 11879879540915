import { useEffect, useState } from 'react';
import superagent from '@ott/superagent';
import { EXCLUDE_OFFERS_NAMES } from 'constants/offers';
import Cookie from 'js-cookie';
import { lang } from '@ott/l10n';
import { Offer } from 'src/components/organisms/NewOffersSlider/types';
import { useSelector } from 'react-redux';

const REFERRER: string = Cookie.get('referrer') || '';
const DATA_API_URL = '/_mark/offers';

type Response = {
  body: {
    error: string | null | {};
    result: {
      buyerId: string;
      offers: Offer[];
    };
  };
};

type AdditionalDataResponse = {
  body: {
    error: string | null | {};
    result: Offer;
  };
};

export const useOffersData = ({
  product,
}: {
  product: string;
}): { data: Offer[]; isLoading: boolean } => {
  const [data, setData] = useState<Offer[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [canFetch, setCanFetch] = useState(false)
  const { data: { auth: isAuthorized = undefined } = {} } = useSelector((state) => state.common.auth);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isAuthorized === undefined) {
        setCanFetch(true)
      }
    }, 10000)
    return () => clearTimeout(timer);
  }, [isAuthorized]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const res: Response = await superagent.get(`${DATA_API_URL}/buyer`).query({
        fullOffers: true,
        exclude: EXCLUDE_OFFERS_NAMES,
        useCache: true,
        reseller: REFERRER,
        product: product,
        lang,
      });

      if (res?.body?.result?.offers) {
        setData(res.body.result.offers);
      }
      setIsLoading(false);
    };

    if(isAuthorized !== undefined || canFetch) {
      void fetchData();
    }
  }, [product, isAuthorized, canFetch]);

  return { data, isLoading };
};

export const getAdditionalOffersData = async ({ id }: { id: string }) => {
  const res: AdditionalDataResponse = await superagent.get(`${DATA_API_URL}/${id}`).query({
    additionalData: true,
  });

  return res?.body?.result;
};
