import React from 'react';
import localStorage from 'local-storage';
import cls from 'classnames/bind';

import FormattedData from '@ott/formatted-date';
import { Container, Row, Col } from '@ott/grid';

import SimpleSlider from 'organisms/SimpleSlider';

import s from './Feedback.scss';

const cx = cls.bind(s);

import { hot } from 'react-hot-loader';

class Feedback extends React.PureComponent {
  state = {};
  static propTypes = {};
  static defaultProps = {};

  static responsive = [
    {
      breakpoint: 1132,
      settings: {
        slidesToShow: 2,
        dots: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        dots: true,
      },
    },
    {
      breakpoint: 460,
      settings: {
        slidesToShow: 1,
        dots: true,
      },
    },
  ];

  componentDidMount() {
    let date = localStorage.get('feedbackDate');
    if (!date) {
      date = Date.now();
      localStorage.set('feedbackDate', date);
    }

    this.setState({ date });
  }

  renderTitle(title) {
    if (!title) {
      return null;
    }

    return (
      <Row>
        <Col desktop={12} tablet={12} mobile={4}>
          <h2 className={cx('title')}>{title}</h2>
        </Col>
      </Row>
    );
  }

  render() {
    const { className, title, reports } = this.props;

    const { date } = this.state;

    if (!reports || !reports.length || !date) {
      return null;
    }

    const titleNode = this.renderTitle(title);

    return (
      <Container className={cx('Feedback', className)}>
        {titleNode}
        <SimpleSlider
          infinite={false}
          swipeToSlide={true}
          arrows={true}
          slidesToShow={3}
          className={cx('slider')}
          dots={false}
          responsive={Feedback.responsive}
        >
          {reports.map((report, index) => (
            <div className={cx('wrap')} key={index}>
              <div className={cx('report')}>
                <div>
                  <span className={cx('rating')}>{report.rating}</span>
                  <span className={cx('ratingText')}>{report.ratingText}</span>
                </div>
                <div className={cx('text')}>{report.text}</div>
                <div className={cx('footer')}>
                  <span className={cx('nickname')}>{report.nickname}</span>
                  <span className={cx('date')}>
                    {<FormattedData utcDate={date} isShortMonth={true} />}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </SimpleSlider>
      </Container>
    );
  }
}

export default hot(module)(Feedback);
