import T from './types';
import loader from '@ott/utility-loader';

const initialState = {
  sendMobileAppLinkSMSStatus: loader.initial(),
};

module.exports = function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case T.SEND_MOBILE_APP_LINK_SMS: {
      return {
        ...state,
        sendMobileAppLinkSMSStatus: loader.begin(),
      };
    }
    case T.SEND_MOBILE_APP_LINK_SMS_SUCCESS: {
      return {
        ...state,
        sendMobileAppLinkSMSStatus: loader.success(),
      };
    }
    case T.SEND_MOBILE_APP_LINK_SMS_FAIL: {
      const {
        error: { error },
      } = action;
      return {
        ...state,
        sendMobileAppLinkSMSStatus: loader.error(error),
      };
    }
    default: {
      return state;
    }
  }
};
