import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, matchPath, Switch } from 'react-router-dom';
import { rootContext, childContext, getRootChildContext } from '@ott/context-utility';
import { ConfigContextProvider } from '@ott/config-context';
import { LayoutContextProvider } from '@ott/layout-context';
import { MessageContextProvider } from '@ott/message';
import { VCModals } from '@ott/virtual-card';
import _throttle from 'lodash/throttle';

import IndexPage from 'src/components/pages/IndexPage';
import SurchargePage from 'src/components/pages/SurchargePage';

import { updateViewport } from 'src/redux/modules/common/viewport/actions';

import { pageInfo } from 'src/redux/selectors/service';

import { DEFAULT_PRODUCT } from 'constants/common';
import { limiter } from '@ott/superagent';

const mapStateToProps = (state) => {
  const {
    index: { config },
  } = state;

  return {
    ...pageInfo(state),
    config,
  };
};

@connect(mapStateToProps)
class App extends React.PureComponent {
  static contextTypes = rootContext;

  static childContextTypes = childContext;

  getChildContext() {
    return getRootChildContext(this.props, this.context);
  }

  componentDidMount() {
    this.updateViewport();

    window.addEventListener('resize', this.throttledUpdateViewport);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.throttledUpdateViewport);
  }

  updateViewport = () => {
    const { dispatch } = this.props;

    dispatch(
      updateViewport({
        width: window.innerWidth,
      })
    );
  };

  throttledUpdateViewport = _throttle(this.updateViewport, 300);

  renderSurchargeRoute = ({ history }) => {
    const matched = matchPath(history.location.pathname, {
      path: '/:lang/surcharge/:product/:surchargeId',
    });

    const { surchargeId, product, lang } = matched ? matched.params : {};
    const { config } = this.props;

    const currentConfig = config[`${lang}/${product}`];

    return (
      <SurchargePage
        config={currentConfig}
        surchargeId={surchargeId}
        lang={lang}
        product={product}
      />
    );
  };

  renderIndexRoute = ({ history }) => {
    const langMatch = matchPath(history.location.pathname, {
      path: '/:lang/',
    });

    const fullUrlMatch = matchPath(history.location.pathname, {
      path: '/:lang/:product',
    });

    const { product = '' } = fullUrlMatch ? fullUrlMatch.params : {};
    const { lang } = langMatch ? langMatch.params : { lang: 'ru' };
    const { config } = this.props;

    const currentConfig = config[`${lang}/${product}`];

    if (currentConfig.statusCode === 404) {
      return this.renderErrorRoute({ history });
    }

    return <IndexPage config={currentConfig} product={product || DEFAULT_PRODUCT} />;
  };

  renderErrorRoute = ({ history }) => {
    const langMatch = matchPath(history.location.pathname, {
      path: '/:lang/',
    });

    const { lang } = langMatch.params;
    const { config } = this.props;

    return <IndexPage config={config[`${lang}/404`]} product="404" />;
  };

  renderRouteNode() {
    return (
      <Switch>
        <Route exact={true} path="/" render={this.renderIndexRoute} />
        <Route
          exact={true}
          // сюда добавлять вертикали, когда они начнут поддерживать доплаты
          path="/:lang/surcharge/:product?/:surchargeId?"
          render={this.renderSurchargeRoute}
        />
        <Route
          exact={true}
          path="/:lang/:product(avia|poezda|tours|hotels|cars|bus|activities|)"
          render={this.renderIndexRoute}
        />
        <Route render={this.renderErrorRoute} />
      </Switch>
    );
  }

  renderRouterNode() {
    const routeNode = this.renderRouteNode();

    if (__SERVER__) {
      return routeNode;
    }

    return <BrowserRouter>{routeNode}</BrowserRouter>;
  }

  render() {
    return (
      <ConfigContextProvider value={{}}>
        <MessageContextProvider>
          <LayoutContextProvider>
            {this.renderRouterNode()}
            <VCModals />
            <limiter.Captcha />
          </LayoutContextProvider>
        </MessageContextProvider>
      </ConfigContextProvider>
    );
  }
}

export default App;
