import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames/bind';
import { l10n } from '@ott/l10n';
import AppleLogoIcon from '@ott/icon-apple';
import AndroidLogoIcon from '@ott/icon-android';
import HuaweiLogoIcon from '@ott/icon-huawei';
import AppstoreIcon from '@ott/icon-appstore';
import GooglePlayIcon from '@ott/icon-google-play';
import AppGalleryIcon from '@ott/icon-app-gallery';
import { withLayoutContext } from '@ott/layout-context';

import MobileAppSMSLinkForm from './blocks/MobileAppSMSLinkForm';

import s from './DesktopView.scss';

const cx = cls.bind(s);

@withLayoutContext
class DesktopView extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    image: PropTypes.string,
    apps: PropTypes.shape({
      ios: PropTypes.shape({
        appId: PropTypes.string.isRequired,
        target: PropTypes.string,
        rel: PropTypes.string,
      }),
      android: PropTypes.shape({
        appId: PropTypes.string.isRequired,
        target: PropTypes.string,
        rel: PropTypes.string,
      }),
      huawei: PropTypes.shape({
        appId: PropTypes.string.isRequired,
        target: PropTypes.string,
        rel: PropTypes.string,
      }),
    }),
  };

  static defaultProps = {};

  constructor(props, ctx) {
    super(props, ctx);
  }

  renderApps() {
    const { apps: { ios, android, huawei } = {} } = this.props;

    const iosNode = ios && (
      <div key="ios_app" className={cx('storeLink')} data-locator="ios-app-link">
        <AppleLogoIcon className={cx('app-icon')} w={36} h={36} />
        <AppstoreIcon className={cx('app-icon--tablet')} />
      </div>
    );

    const androidNode = android && (
      <div key="android_app" className={cx('storeLink')} data-locator="android-app-link">
        <AndroidLogoIcon className={cx('app-icon')} w={36} h={36} />
        <GooglePlayIcon className={cx('app-icon--tablet')} />
      </div>
    );

    const huaweiNode = huawei && (
      <div key="huawei_app" className={cx('storeLink')} data-locator="huawei-app-link">
        <HuaweiLogoIcon className={cx('app-icon')} w={24} h={24} />
        <AppGalleryIcon className={cx('app-icon--tablet')} />
      </div>
    );

    return (
      <div className={cx('storesIcons')}>
        {iosNode}
        {androidNode}
        {huaweiNode}
      </div>
    );
  }

  render() {
    const { className, image, isDesktop } = this.props;

    const appsNode = this.renderApps();

    return (
      <div className={cx('DesktopView', className)} data-locator="app-banner">
        <div className={cx('content')}>
          <h2 className={cx('caption')}>{l10n('mobileAppBanner.caption')}</h2>
          <div className={cx('title')}>{l10n('mobileAppBanner.title')}</div>
          <div className={cx('getLinkCaption')}>{l10n('mobileAppBanner.getLinkCaption')}</div>
          {isDesktop && (
            <div className={cx('formWrapper')}>
              <MobileAppSMSLinkForm />
            </div>
          )}
          {appsNode}
        </div>
        <div
          className={cx('imageContainer')}
          style={{
            backgroundImage: `url(${image})`,
          }}
          data-locator="desktop-view-image"
        />
      </div>
    );
  }
}

export default DesktopView;
