import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames/bind';

import { l10n } from '@ott/l10n';

import s from './MobileView.scss';

const cx = cls.bind(s);

class MobileView extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    image: PropTypes.string,
    apps: PropTypes.shape({
      ios: PropTypes.shape({
        appId: PropTypes.string.isRequired,
        target: PropTypes.string,
        rel: PropTypes.string,
      }),
      android: PropTypes.shape({
        appId: PropTypes.string.isRequired,
        target: PropTypes.string,
        rel: PropTypes.string,
      }),
    }),
  };

  static defaultProps = {
    apps: {},
  };

  constructor(props, ctx) {
    super(props, ctx);
  }

  getAppsLink({ ios: { appId: iosAppId }, android: { appId: androidAppId } }) {
    return `https://app.adjust.com/${androidAppId}_${iosAppId}?campaign=new_adaptive_mobile`;
  }

  render() {
    const { className, apps, image } = this.props;

    if (!apps.android || !apps.ios) {
      return null;
    }

    const appLink = this.getAppsLink(apps);

    return (
      <a
        className={cx('MobileView', className)}
        href={appLink}
        rel={apps.ios.rel}
        target="_blank"
        style={{
          backgroundImage: `url(${image})`,
        }}
        data-locator="app-banner"
      >
        <h2 className={cx('caption')}>{l10n('mobileAppBanner.mobileCaption')}</h2>
        <div className={cx('title')}>{l10n('mobileAppBanner.mobileTitle')}</div>
        <div className={cx('getLinkCaption')}>
          {l10n('mobileAppBanner.mobileGetLinkCaption')}
          <div className={cx('icon')} />
        </div>
      </a>
    );
  }
}

export default MobileView;
