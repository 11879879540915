import * as T from '../types';

const initialState = {
  surchargeId: '',
  currentGate: null,
  paymentGates: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case T.SET_SURCHARGE_ID: {
      return {
        ...state,
        surchargeId: action.surchargeId,
      };
    }

    case T.SET_PAYMENT_GATES: {
      return {
        ...state,
        paymentGates: action.paymentGates,
      };
    }

    case T.SET_CURRENT_PAYMENT_GATE: {
      return {
        ...state,
        currentGate: action.gate,
      };
    }

    default:
      return state;
  }
};
