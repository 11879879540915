// Если у пользователя кука referrer = affiliate и в request headers есть referrer,
// отличный от сайта onetwotrip, то передавать КМ событие affiliate_referrer
// кука referrer
// кука referrer_mrk
// кука referrer_first
// referrer из request headers
// дата и время
// url страницы, откуда отправляется это событие
import KM from '@ott/utility-km';
import Cookie from 'js-cookie';

const getOptions = () => {
  const options = {
    referrer: Cookie.get('referrer'),
    referrer_mrk: Cookie.get('referrer_mrk'),
    referrer_first: Cookie.get('referrer_first'),
    request_referrer: document.referrer,
    url: window.location.href,
  };
  return { obj: options };
};

(function() {
  const reqHeadersReferrer = document.referrer;

  if (reqHeadersReferrer && !reqHeadersReferrer.includes('onetwotrip.com')) {
    const options = getOptions();
    KM('affiliate_referrer', options);
  }
})();
