const errorSelector = ({ surcharge }) => {
  const { payPolling, payRequest, surchargeData } = surcharge;

  return {
    payPollingError: payPolling.error,
    payRequestError: payRequest.error,
    surchargeDataError: surchargeData.error,
  };
};

export default errorSelector;
