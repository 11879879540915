import React from 'react';
import cls from 'classnames';
import { Container, Row } from '@ott/grid';
import styles from './Title.scss';

const Title = ({ className, children }) => {
  return (
    <Container>
      <Row>
        <h2 className={cls(styles.title, className)}>{children}</h2>
      </Row>
    </Container>
  );
};

export default Title;
