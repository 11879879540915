export function checkPermissions(permissions, authData) {
  if (typeof permissions !== 'undefined') {
    return permissions.every(permission => {
      if (permission === 'Guest') {
        return authData && !authData.auth;
      }
      if (permission === 'User') {
        return !authData || authData.auth;
      }
      return false;
    });
  }
  return true;
}
