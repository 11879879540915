import React from 'react';
import styles from './OfferWrapper.scss';
import { OfferWrapperProps } from '../../types';
import { getCardPropsFromOfferData } from './helpers';
import BaseOfferCard from '../BaseOfferCard';

const InfoWrapper = ({ data, children, ...props }: OfferWrapperProps) => {
  const cardProps = getCardPropsFromOfferData(data);

  return (
    <div className={styles.containerInfo} {...props}>
      <BaseOfferCard {...cardProps} />
    </div>
  );
};

export default InfoWrapper;
