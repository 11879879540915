import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames/bind';

import s from './Spreader.scss';

const cx = cls.bind(s);

class Spreader extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
  };

  static defaultProps = {};

  constructor(props, ctx) {
    super(props, ctx);
  }

  render() {
    const { children, className } = this.props;

    return <div className={cx('Spreader', className)}>{children}</div>;
  }
}

export default Spreader;
