import React from 'react';
import PropTypes from 'prop-types';

function BadgeNewIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="16"
      viewBox="0 0 24 16"
      className={className}
    >
      <defs />
      <g fill="none" fillRule="evenodd">
        <rect width="24" height="16" fill="#FFD41E" rx="4" />
        <path
          fill="#212121"
          fillRule="nonzero"
          d="M5 10.9V7.5a1 1 0 011-.6c.4 0 .6 0 .7.2l.2.7v3.1h1V7.7C8 6.6 7.5 6 6.5 6c-.5 0-1 .2-1.4.6v-.5H4v4.8h1zm6.2 0c.4 0 .8 0 1-.2.4-.1.7-.3.8-.6l-.5-.6c-.3.4-.8.6-1.2.6-.4 0-.7 0-1-.3-.2-.3-.3-.6-.3-1H13v-.4c0-.8-.2-1.4-.5-1.8-.4-.4-.9-.6-1.5-.6a2 2 0 00-2 1.2L9 8.5v.1c0 .7.2 1.3.6 1.7.5.5 1 .7 1.7.7zM12 8H10c0-.3.2-.6.3-.8.2-.2.5-.3.8-.3.3 0 .5 0 .7.2l.3.9zm3.7 2.9l1-3.3 1 3.3h.9L20 6.1h-1l-.8 3.3-1-3.3h-.8l-1 3.3-.8-3.3h-1l1.3 4.8h.9z"
        />
      </g>
    </svg>
  );
}

BadgeNewIcon.propTypes = {
  className: PropTypes.string,
};

export default BadgeNewIcon;
