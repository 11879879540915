import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { lang } from '@ott/l10n';
import { withLayoutContext } from '@ott/layout-context';
import Cookie from 'js-cookie';

import _debounce from 'lodash/debounce';
import _last from 'lodash/last';
import _get from 'lodash/get';
import _times from 'lodash/times';
import _pickBy from 'lodash/pickBy';

import metrics from 'utility/metrics';
import trackGA from 'utility/trackGA';

import { Swipeable } from 'react-swipeable';
import { Container, Row, Col } from './blocks/Grid';
import OfferCard, { offersAdapter } from '@ott/offer-card';
import ChevronIcon from 'components/icons/ChevronIcon';

import { OFFERS_SOURCE } from 'constants/offers';

import { fetchOffers, resetOffers } from 'src/redux/modules/offers/items/actions';
import { generateAdditionalData } from 'src/redux/modules/offers/additionalData/actions';
import { getAdditionalOffersData } from 'src/redux/selectors/offersSelector';

import { openPopup } from 'src/redux/modules/common/customPopup/actions';
import { showAuth } from 'src/redux/modules/common/authModal/actions';

import styles from './OffersSlider.scss';

const REFERRER = Cookie.get('referrer');

const gutterWidth = 16;
const ContainerForwardRef = React.forwardRef(({ children, ...restProps }, ref) => (
  <Container innerRef={ref} {...restProps}>
    {children}
  </Container>
));

const mapStateToProps = (state, ownProps) => {
  const {
    common: { auth },
    offers: {
      items: { data: offersData = {}, ...restOffersItemsData },
    },
  } = state;

  const offersItems = offersAdapter(ownProps.items || offersData.offers, ownProps.source, { auth });

  return {
    additionalDataState: getAdditionalOffersData(state),
    offersStatus: restOffersItemsData,
    offers: offersItems,
    auth,
  };
};

@withLayoutContext
@connect(mapStateToProps)
class OffersSlider extends React.PureComponent {
  static propTypes = {
    offers: PropTypes.arrayOf(
      PropTypes.shape({
        offerData: PropTypes.shape({}),
        offerMeta: PropTypes.shape({
          cardColor: PropTypes.oneOf(['white', 'yellow', 'blue']),
          isBig: PropTypes.bool,
        }),
      })
    ),
    offersStatus: PropTypes.shape({}),

    // 'local' for items from config
    // 'remote' for items from API
    source: PropTypes.oneOf([OFFERS_SOURCE.LOCAL, OFFERS_SOURCE.REMOTE]),
    product: PropTypes.string,

    auth: PropTypes.shape({}),
    className: PropTypes.string,
    negativeOffsetTop: PropTypes.bool,
    isMobile: PropTypes.bool,
  };

  static defaultProps = {
    offers: [],
  };

  constructor(props, ctx) {
    super(props, ctx);

    this.state = {
      xOffset: 0,
      width: 0,
      isSwiping: false,
      items: props.offers,
      isMounted: false,
      isEventSended: false,
    };

    this.startSwipingOffset = 0;
    this.rootRef = React.createRef();
    this.itemRefs = [];

    this.handleNextButtonClick = this.handleNextButtonClick.bind(this);
    this.handlePrevButtonClick = this.handlePrevButtonClick.bind(this);
    this.handleSwiping = this.handleSwiping.bind(this);
    this.handleSwiped = this.handleSwiped.bind(this);
    this.handleResize = _debounce(this.handleResize.bind(this), 100);
  }

  componentDidMount() {
    this.setState({ isMounted: true });

    if (!this.isLoadingAuth(this.props) && _get(this.props, 'auth.data.auth') !== undefined) {
      this.reloadData();
    }

    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps) {
    const { offers: prevOffers, product: prevProduct } = prevProps;
    const { offers, product, offersStatus } = this.props;
    const { isEventSended } = this.state;

    const isFromConfig = Boolean(offers.length) && prevOffers.length === offers.length;

    const areOffersLoaded = !prevOffers.length && offers.length;
    const wereOffersLoadedAfterAuthReload =
      this.isLoadingAuth(prevProps) && !this.isLoadingAuth(this.props) && offersStatus.initial;
    const hasChangedProduct = product !== prevProduct;

    if (
      this.hasAuthChanged(prevProps, this.props) ||
      wereOffersLoadedAfterAuthReload ||
      hasChangedProduct
    ) {
      this.reloadData();
    }

    if (areOffersLoaded || (isFromConfig && !isEventSended)) {
      this.itemRefs = this.itemRefs.reduce((newItemRefs, currentRef) => {
        if (!currentRef.ref) {
          return newItemRefs;
        }

        newItemRefs.push({ ...currentRef });

        return newItemRefs;
      }, []);
      this.setState(
        (state) => ({
          ...state,
          items: offers,
          isEventSended: true,
        }),
        () => {
          this.updateWidth();
          this.sendMetricsEvents();
        }
      );
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    window.removeEventListener('resize', this.handleResize);
    dispatch(resetOffers());
  }

  loadOffers = () => {
    const { dispatch, product } = this.props;
    this.setState({ items: this.renderMockOffersNodes() }, () => {
      this.updateWidth();
    });

    const params = _pickBy(
      {
        reseller: REFERRER || '',
        product,
        lang,
      },
      (v) => v !== undefined
    );

    dispatch(fetchOffers(params));
  };

  reloadData = () => {
    const { source, offers } = this.props;
    if (source === OFFERS_SOURCE.REMOTE) {
      this.loadOffers();
    } else if (source === OFFERS_SOURCE.LOCAL) {
      this.setState({ items: [...offers] }, () => {
        this.updateWidth();
      });
    }
  };

  addRef = (ref, key) => {
    const itemRefObj = {
      key,
      ref,
    };

    const existingItemRefIndex = this.itemRefs.findIndex((itemRef) => itemRef.key === key);

    if (existingItemRefIndex >= 0) {
      this.itemRefs[existingItemRefIndex] = itemRefObj;

      return this.itemRefs[existingItemRefIndex];
    }

    return this.itemRefs.push(itemRefObj);
  };

  getOffersActions = () => {
    const { dispatch } = this.props;
    return {
      routingByLink: (data) => {
        const {
          offerData: { buttonLink },
        } = data;

        const { history } = this.props;

        history.push(buttonLink);
        document.body.scrollIntoView({
          behavior: 'smooth',
        });
      },
      scrollToELement: (data) => {
        const {
          offerMeta: { behaviorData },
        } = data;

        document.getElementById(behaviorData).scrollIntoView({
          behavior: 'smooth',
        });
      },
      authorize: () => {
        dispatch(showAuth());
      },
      openCustomPopup: (data) => {
        const {
          offerMeta: { behaviorData: { customPopup: openCustomPopupData } = {} },
        } = data;

        dispatch(openPopup(openCustomPopupData));
      },
    };
  };

  getCardColorByIndex = (index) => {
    const itemIndex = index - Math.floor(index / 3) * 3;

    switch (itemIndex) {
      case 0:
        return 'yellow';
      case 1:
        // @NB временно изменен (LAN-4216)
        // return 'blue';
        return 'white';
      case 2:
        return 'white';
      default:
        return 'white';
    }
  };

  isLoadingOffers() {
    const {
      offersStatus: { loading },
      offers,
    } = this.props;

    return loading || !offers;
  }

  updateWidth() {
    const { xOffset } = this.state;
    const rootEl = this.rootRef.current;
    const { width } = this.state;

    if (!rootEl) {
      return;
    }

    this.setState({
      width: rootEl.clientWidth,
    });

    if (width < 1064) {
      this.setXOffset(0);
    } else {
      this.setXOffset(xOffset);
    }
  }

  isLoadingAuth(props) {
    const {
      auth: { infoStatus, exitStatus, enterStatus, oAuthStatus },
    } = props;

    return infoStatus.loading || exitStatus.loading || enterStatus.loading || oAuthStatus.loading;
  }

  isAuthorized(props = {}) {
    return _get(props, 'auth.data.auth', false);
  }

  hasAuthChanged(prevProps, props) {
    const hasInfoStatusChanged =
      _get(prevProps, 'auth.infoStatus.success') !== _get(props, 'auth.infoStatus.success');
    const hasEnterStatusChanged =
      _get(prevProps, 'auth.enterStatus.success') !== _get(props, 'auth.enterStatus.success');
    const hasExitStatusChanged =
      _get(prevProps, 'auth.exitStatus.success') !== _get(props, 'auth.exitStatus.success');
    const hasAuthorizedChanged = this.isAuthorized(prevProps) !== this.isAuthorized(props);
    return (
      (hasInfoStatusChanged || hasEnterStatusChanged || hasExitStatusChanged) &&
      hasAuthorizedChanged
    );
  }

  hasPreviousSlideImage(offerItems, index) {
    return index > 0 && !!offerItems[index - 1].offerData.image;
  }

  sendMetricsEvents = () => {
    const { items } = this.state;
    if (items && items.length) {
      metrics('main_offers_show', {
        offers: items.map(({ offerData }) => offerData.id).join(',') || '',
      });
    }
  };

  getElementWidth(elRef) {
    const rootEl = elRef.rootRef;

    if (!rootEl) {
      return 0;
    }

    return rootEl.clientWidth;
  }

  getPoints() {
    return this.itemRefs.reduce(
      (acc, itemRef) => {
        if (itemRef.ref) {
          const x = _last(acc) + this.getElementWidth(itemRef.ref) + gutterWidth;
          acc.push(x);
        }
        return acc;
      },
      [0]
    );
  }

  getPrevOffset() {
    const { xOffset } = this.state;

    const points = this.getPoints();

    return Math.max(...points.filter((x) => x < xOffset));
  }

  getNextOffset() {
    const { xOffset } = this.state;

    const points = this.getPoints();

    return Math.min(...points.filter((x) => x > xOffset));
  }

  setXOffset(xOffset, isSwipe) {
    if (xOffset < 0) {
      xOffset = 0;
    }

    const maxXOffset = this.getMaxXOffset();
    if (xOffset > maxXOffset && maxXOffset >= 0 && isSwipe) {
      xOffset = maxXOffset;
    }

    this.setState({
      xOffset,
    });
  }

  getTotalWidth() {
    return this.itemRefs.reduce((acc, itemRef, index) => {
      if (itemRef.ref) {
        acc += this.getElementWidth(itemRef.ref);
        if (index !== this.itemRefs.length - 1) {
          acc += gutterWidth;
        }
      }
      return acc;
    }, 0);
  }

  getMaxXOffset() {
    const totalWidth = this.getTotalWidth();
    const { width } = this.state;

    return totalWidth - width;
  }

  handleNextButtonClick() {
    this.setXOffset(this.getNextOffset());
  }

  handlePrevButtonClick() {
    this.setXOffset(this.getPrevOffset());
  }

  handleResize() {
    this.updateWidth();
  }

  handleMetricsOfferClick = (metricsData, customName) => {
    const { items = [], partnerId } = this.props;
    metrics(customName || 'main_offers_click', metricsData);

    const currentItem = items.find((item) => item.key === metricsData.offer_id);
    if (currentItem && currentItem.gaEvent) {
      trackGA({
        hitType: 'event',
        eventCategory: partnerId,
        eventAction: currentItem.gaEvent,
      });
    }
  };

  handleSwiping(e) {
    const { isSwiping, xOffset } = this.state;

    // Если свайп вертикальный, просто вернемся из функции
    if (Math.abs(e.deltaY) > Math.abs(e.deltaX)) {
      this.setState({ isSwiping: false });
      return;
    }

    if (!isSwiping) {
      this.startSwipingOffset = xOffset;
      this.setState({ isSwiping: true });
    }

    this.setState({
      xOffset: this.startSwipingOffset + e.deltaX,
    });
  }

  handleSwiped(e) {
    const { isMobile } = this.props;
    const { width } = this.state;
    const { deltaX, deltaY } = e;

    // Если свайп вертикальный, просто вернемся из функции
    if (Math.abs(deltaY) > Math.abs(deltaX)) {
      this.setState({ isSwiping: false });
      return;
    }

    let newXOffset = this.startSwipingOffset + deltaX;

    if (isMobile) {
      // свайпнули более чем на 15% ширины карточки
      if (Math.abs(deltaX / width) > 0.15) {
        newXOffset = deltaX > 0 ? this.getNextOffset() : this.getPrevOffset();
      } else {
        // не дотянули - возвращаем на место
        newXOffset = deltaX > 0 ? this.getPrevOffset() : this.getNextOffset();
      }
    }

    this.setXOffset(newXOffset, true);
    this.setState({ isSwiping: false });
  }

  handleDotClick(_, index) {
    if (this.state.isSwiping) {
      return;
    }

    const points = this.getPoints();
    this.setState({ xOffset: points[index] });
  }

  renderMockOffersNodes = () => {
    return _times(4, (index) => (
      <Col
        desktop={3}
        tablet={12}
        mobile={4}
        key={`${index}_mock_container`}
        className={styles.offerContainer}
      >
        <div
          key={`mockOffer${index}`}
          className={cls(styles.offer, styles['offer--mock'], styles['offer--small'])}
        >
          <div className={styles.mock__headerPart}>
            <div className={cls(styles.mockIcon, styles.mockGradient)}>&nbsp;</div>
          </div>
          <div className={styles.mockContentPart}>
            <div className={styles.mockTextContent}>
              <div className={cls(styles.mockTitle, styles.mockGradient)}>&nbsp;</div>
              <div className={cls(styles.mockDescriptionLong, styles.mockGradient)}>&nbsp;</div>
              <div className={cls(styles.mockDescriptionShort, styles.mockGradient)}>&nbsp;</div>
            </div>
            <div className={cls(styles.mockAction, styles.mockGradient)}>&nbsp;</div>
          </div>
        </div>
      </Col>
    ));
  };

  renderItemsNode = () => {
    const { offers, additionalDataState, dispatch, source, isMobile } = this.props;

    if (this.isLoadingOffers() || !offers.length) {
      return this.renderMockOffersNodes();
    }

    const removedImagesIndices = [];

    return offers.map((item, index) => {
      const { offerData, offerMeta } = item;
      const isBig = Boolean(
        (typeof offerMeta.isBig !== 'undefined' ? offerMeta.isBig : index === 0) && offerData.image
      );
      const cardColor =
        source === OFFERS_SOURCE.LOCAL ? offerMeta.cardColor : this.getCardColorByIndex(index);
      const hideImage =
        source === OFFERS_SOURCE.REMOTE &&
        this.hasPreviousSlideImage(offers, index) &&
        !removedImagesIndices.find((removedIndex) => removedIndex === index - 1);

      if (hideImage) {
        removedImagesIndices.push(index);
      }

      return (
        <Col
          desktop={isBig ? 6 : 3}
          tablet={12}
          mobile={4}
          key={`${offerData.id}_container`}
          className={styles.offerContainer}
        >
          <OfferCard
            key={offerData.id}
            ref={(el) => this.addRef(el, `${offerData.id}`)}
            className={cls(
              { [styles['offer--big']]: isBig },
              { [styles['offer--small']]: !isBig },
              styles.offer
            )}
            isActive={true}
            isMobileLayout={isMobile}
            offerMeta={{
              ...offerMeta,
              cardColor,
              hideImage,
              isBig,
            }}
            offerData={{
              ...offerData,
            }}
            additionalOfferData={{
              ...additionalDataState,
            }}
            getAdditionalOfferData={(id, type) => {
              dispatch(generateAdditionalData(id, type));
            }}
            actions={{
              ...this.getOffersActions(),
            }}
            metrics={{
              click: this.handleMetricsOfferClick,
            }}
          />
        </Col>
      );
    });
  };

  renderPrevButton() {
    const { isMobile } = this.props;
    const { xOffset, isSwiping, isMounted } = this.state;

    if (isMobile || !isMounted) {
      return null;
    }

    const hidePrevButton = xOffset <= 0 || isSwiping;
    return (
      <div
        key="OffersSlider__prevButton"
        className={cls(styles.prevButton, {
          [styles['prevButton--isHidden']]: hidePrevButton,
        })}
        onClick={this.handlePrevButtonClick}
      >
        <ChevronIcon direction="left" />
      </div>
    );
  }

  renderNextButton() {
    const { isMobile } = this.props;
    const { xOffset, isSwiping, isMounted } = this.state;

    if (isMobile || !isMounted) {
      return null;
    }

    const maxXOffset = this.getMaxXOffset();
    const hideNextButton = xOffset >= maxXOffset || isSwiping;
    return (
      <div
        key="OffersSlider__nextButton"
        className={cls(styles.nextButton, {
          [styles['nextButton--isHidden']]: hideNextButton,
        })}
        onClick={this.handleNextButtonClick}
      >
        <ChevronIcon direction="right" />
      </div>
    );
  }

  renderDotsNode() {
    const { isMobile } = this.props;
    const { items, isMounted, xOffset } = this.state;

    if (!isMobile || !isMounted) {
      return null;
    }

    const points = this.getPoints();

    const activeIndex = points.findIndex((p) => p === xOffset);

    this.setState({
      activeIndex,
    });

    return (
      <div className={styles.dotsContainer} key="dotsContainer">
        {items.map((item, index) => {
          return (
            <div
              key={index}
              className={cls(styles.dot, {
                [styles['dot--isActive']]: index === activeIndex,
              })}
              data-locator="dot"
              onClick={this.handleDotClick.bind(this, item, index)}
            />
          );
        })}
      </div>
    );
  }

  render() {
    const { className, negativeOffsetTop, offersStatus, offers } = this.props;

    const { xOffset, isSwiping } = this.state;

    const offersHaveErrors = offersStatus.error;
    const offersAreEmpty =
      !offers.length &&
      !offersStatus.loading &&
      !this.isLoadingAuth(this.props) &&
      !offersHaveErrors &&
      offersStatus.success;

    if (offersHaveErrors || offersAreEmpty) {
      return null;
    }

    const itemsNode = this.renderItemsNode();
    const prevButtonNode = this.renderPrevButton();
    const nextButtonNode = this.renderNextButton();
    const dotsNode = this.renderDotsNode();

    return (
      <div
        className={cls(
          styles.OffersSlider,
          className,
          negativeOffsetTop && styles['OffersSlider--negativeOffsetTop']
        )}
        data-locator="OffersSlider"
      >
        <ContainerForwardRef
          className={styles.gridSpreader}
          ref={this.rootRef}
          key="swipeableContainer"
        >
          <Swipeable
            className={cls(styles.container, {
              [styles['container--isSwiping']]: isSwiping,
            })}
            onSwiping={this.handleSwiping}
            onSwiped={this.handleSwiped}
            style={{
              transform: `translate(${-xOffset}px, 0)`,
            }}
            preventDefaultTouchmoveEvent={true}
          >
            <Row className={styles.row}>{itemsNode}</Row>
          </Swipeable>
          {prevButtonNode}
          {nextButtonNode}
          {dotsNode}
        </ContainerForwardRef>
      </div>
    );
  }
}

export default withRouter(OffersSlider);
