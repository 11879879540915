import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { BusSearchFormWithReactQueryProvider as SearchForm } from '@ott/bus-search-form';

import setFormReadyParam from 'utility/setFormReadyParam';

import qs from 'qs';

@withRouter
class BusSearchForm extends React.PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func,
    showBusinessTripCheckbox: PropTypes.bool,
  };

  static defaultProps = {
    showBusinessTripCheckbox: false,
  };

  constructor(props, ctx) {
    super(props, ctx);
  }

  render() {
    return <SearchForm {...this.props} />;
  }
}

class BusSearchFormWrap extends React.PureComponent {
  static propTypes = {
    urls: PropTypes.objectOf({
      busSearch: PropTypes.string,
      flightsSearch: PropTypes.string,
      hotelsSearch: PropTypes.string,
      railwaysSearch: PropTypes.string,
      toursSearch: PropTypes.string,
      mobile: PropTypes.objectOf({
        achievements: PropTypes.string,
        auth: PropTypes.string,
        profile: PropTypes.string,
        ticket: PropTypes.string,
        travelexpert: PropTypes.string,
      }),
    }),
    showBusinessTripCheckbox: PropTypes.bool,
  };

  constructor(props, ctx) {
    super(props, ctx);
  }

  componentDidMount() {
    setFormReadyParam('bus');
  }

  handleSubmit = (searchQuery, seoUrlAdditional) => {
    const queryString = qs.stringify(searchQuery);

    window.location.href = `${this.props.urls.busSearch}/${seoUrlAdditional}/?${queryString}`;
  };

  render() {
    const { showBusinessTripCheckbox } = this.props;

    return (
      <BusSearchForm
        onSubmit={this.handleSubmit}
        showBusinessTripCheckbox={showBusinessTripCheckbox}
      />
    );
  }
}

export default BusSearchFormWrap;
