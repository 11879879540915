import React from 'react';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader';

import cls from 'classnames/bind';
import styles from './ToursPriceCalendarWrapper.scss';
const cx = cls.bind(styles);

import ToursPriceCalendar from '@ott/tours-price-calendar';
import Spreader from 'organisms/Spreader';

import trackGA from 'utility/trackGA';

@connect(state => {
  const { priceCalendar } = state.tours;

  return {
    priceCalendar,
  };
})
class ToursPriceCalendarWrapper extends React.PureComponent {
  trackEvent = type => {
    const gaObj = {
      eventLabel: null,
      eventAction: 'click',
      eventCategory: 'tr_index',
    };

    switch (type) {
      case 'countryChange':
        {
          gaObj.eventLabel = 'calendar_country';
        }
        break;
      case 'nightsChange':
        {
          gaObj.eventLabel = 'calendar_nights';
        }
        break;
      case 'chartScroll':
        {
          gaObj.eventLabel = 'calendar_view_more';
        }
        break;
      case 'dateClick':
        {
          gaObj.eventLabel = 'calendar_click';
        }
        break;
    }

    if (gaObj.eventLabel) {
      trackGA(gaObj);
    }
  };

  render() {
    const { priceCalendar, className } = this.props;

    return (
      <Spreader className={cx('ToursPriceCalendarWrapper', className)}>
        <h2 className={cx('title', 'title--isCentered')}>Поиск туров по&nbsp;низким&nbsp;ценам</h2>

        <ToursPriceCalendar {...priceCalendar} trackEvent={this.trackEvent} routeFromOtherApp />
      </Spreader>
    );
  }
}

export default hot(module)(ToursPriceCalendarWrapper);
