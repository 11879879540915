import * as T from './types';

const initialState = {
  show: false,
  formType: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case T.SHOW_AUTH_MODAL: {
      return { show: true, formType: action.formType };
    }
    case T.HIDE_AUTH_MODAL: {
      return { show: false, formType: null };
    }
    default:
      return state;
  }
}
