// поскольку в компоненте нет проверок на серверсайд
const KM = typeof document !== 'undefined' ? require('@ott/utility-km') : () => {};

import Cookie from 'js-cookie';

const getCookieInfo = () => ({
  referrer: Cookie.get('referrer') || '',
  referrer_mrk: Cookie.get('referrer_mrk') || '',
  vid: Cookie.get('vid') || '',
  bidh: Cookie.get('bidh') || '',
  abst: Cookie.get('abst') || '',
});

export default (event, data = {}, meta = {}) => {
  data = Object.assign(getCookieInfo(), data);

  if (__DEV__) {
    console.log(`KM %c${event}`, 'font-weight: bold', { obj: data }, meta);
  } else {
    KM(event, { obj: data }, meta);
  }
};
