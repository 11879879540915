import { getFormSyncErrors } from 'redux-form';
import { createSelector, createStructuredSelector } from 'reselect';
import { CONSTANTS } from '@ott/activities-search-form';
import _getFP from 'lodash/fp/get';

const getLocations = _getFP('activities.searchForm.locations');
const getSelectedLocation = _getFP('activities.searchForm.selectedLocation');
const getLoadingStatus = _getFP('activities.searchForm.status.loading');
const getLastSearches = _getFP('activities.searchForm.queue');

export default createStructuredSelector({
  locations: getLocations,
  selectedLocation: getSelectedLocation,
  queue: getLastSearches,
  isLoading: getLoadingStatus,
  formErrors: getFormSyncErrors(CONSTANTS.FORM_NAME),
});
