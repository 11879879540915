import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hooks as virtualCardHooks } from '@ott/shared-virtual-card-logic';
import { authActions, authSelectors } from '@ott/auth';
import { showAuth } from 'src/redux/modules/common/authModal/actions';

const VCProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { virtualCardFlow: { VirtualCardFlowProvider } } = virtualCardHooks;
  const authData = useSelector(authSelectors.authSelector);

  const showModal = useCallback(() => {
    dispatch(showAuth());
  }, []);

  const auth = {
    visible: false,
    isAuthorized: !!authData.data?.auth,
    isSendPinSuccess: !!authData.sendPinStatus?.success,
    isLoading:
      !!authData.sendPinStatus?.loading ||
      authData.exitStatus?.loading ||
      authData.enterStatus?.loading ||
      authData.infoStatus?.loading,
    passengers: authData.data?.passengers,
    cards: authData.data?.cards,
    phone: authData.data?.phone,
    error: authData.exitStatus?.error || authData.enterStatus?.error,
    showModal,
    getBasicUserInfo: () => dispatch(authActions.getBasicUserInfo()),
    getEnterByPhonePinCode: (values) => dispatch(authActions.getEnterByPhonePinCode(values)),
    enterByPhone: (values) => dispatch(authActions.enterByPhone(values)),
  };

  return (
    <VirtualCardFlowProvider auth={auth}>
      {children}
    </VirtualCardFlowProvider>
  );
};

export default VCProvider;
