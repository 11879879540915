import React, { useRef, useMemo } from 'react';
import { Container } from '@ott/grid';
import Accordion from '../Accordion';
import styles from './SeoText.scss';

type Props = {
  id: string;
  className?: string;
  title: string;
  items: Array<{
    title: string;
    text: string;
  }>;
};

const SeoText = (props: Props) => {
  const { id, className, title, items }: Props = props;
  const accordionRef = useRef(null);

  const accordionData = useMemo(() => {
    return items.map((item, index) => ({
      key: index,
      title: item.title,
      content: item.text,
    }));
  }, [items]);

  return (
    <Container id={id} className={className}>
      <h2 className={styles.title}>{title}</h2>
      <div ref={accordionRef}>
        <Accordion className={styles.accordion} data={accordionData} />
      </div>
    </Container>
  );
};

export default SeoText;
