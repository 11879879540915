import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames/bind';
import { findDOMNode } from 'react-dom';

import NavDropdown from './blocks/NavDropdown';
import NavLink from './blocks/NavLink';

import onlineCall from '@ott/online-call';
import { lang } from '@ott/l10n';

import metrics from 'src/utility/metrics';

import s from './Nav.scss';

const cx = cls.bind(s);

const allowNavIcons = {
  facebook: require('@ott/icon-fb-messenger'),
  skype: require('@ott/icon-skype'),
  telegram: require('@ott/icon-telegram'),
  viber: require('@ott/icon-viber'),
  whatsapp: require('@ott/icon-whatsapp'),
  googlePlay: require('@ott/icon-google-play'),
  phone: require('@ott/icon-call'),
  vk: require('@ott/icon-vk-dialog'),
};

class Nav extends React.PureComponent {
  state = {
    activeDropdownIndex: null,
  };
  static propTypes = {
    links: PropTypes.array,
  };
  static defaultProps = {};

  constructor(props, ctx) {
    super(props, ctx);

    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleClickOutside = (e) => {
    const domNode = findDOMNode(this);

    if (!domNode || !domNode.contains(e.target)) {
      this.setState({
        activeDropdownIndex: null,
      });
    }
  };

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  getDefaultLinkActions = () => {
    return {
      onlineCall: onlineCall.bind(null, lang),
    };
  };

  renderNavGroup = (item, key) => {
    const { title, links } = item;

    const linksNode = links.map((link, index) => this.renderNavDropdownOption(link, index));

    return (
      <div key={key} className={cx('option-group')}>
        {title && <div className={cx('option-group-title')}>{title}</div>}
        {linksNode}
      </div>
    );
  };

  renderNavItemIcon = (icon, props = {}) => {
    const IconComp = allowNavIcons[icon];

    if (!icon || !IconComp) {
      return null;
    }

    return <IconComp w={18} h={18} {...props} />;
  };

  renderNavDropdownOption = (item, key) => {
    const { type, title, icon, iconProps, event, description, ...restProps } = item;

    if (type === 'group') {
      return this.renderNavGroup(item, key);
    }

    const navLinkProps = {
      actions: {
        ...this.getDefaultLinkActions(),
      },
      ...restProps,
    };

    return (
      <React.Fragment>
        <NavLink
          {...navLinkProps}
          key={key}
          className={cx('option', 'option-link')}
          onClick={() => {
            if (event) {
              metrics(event);
            }
          }}
        >
          <div className={cx('option-link-wrapper')}>
            <div className={cx('option-link-title')}>
              {title}
              {this.renderNavItemIcon(icon, iconProps)}
            </div>
            {description && <div className={cx('link-description')}>{description}</div>}
          </div>
        </NavLink>
      </React.Fragment>
    );
  };

  renderNavDropdown = (item, index) => {
    const { title, links, specificLocators } = item;

    return (
      <div key={index} className={cx('nav-item')}>
        <NavDropdown
          index={index}
          isOpen={index === this.state.activeDropdownIndex}
          title={title}
          items={links}
          renderOption={this.renderNavDropdownOption}
          onToggleShow={this.handleToggleDropdown}
          specificLocators={specificLocators}
        />
      </div>
    );
  };

  renderNavItem = (item, key) => {
    const { title, ...restProps } = item;

    return (
      <div key={key} className={cx('nav-item')}>
        <NavLink {...restProps} className={cx('nav-item-link')}>
          {title}
        </NavLink>
      </div>
    );
  };

  handleToggleDropdown = (index) => {
    const nextIndex = this.state.activeDropdownIndex === index ? null : index;

    this.setState({
      activeDropdownIndex: nextIndex,
    });
  };

  render() {
    const { links } = this.props;

    if (!links) {
      return null;
    }

    const linksNodes = links.map((link, index) => {
      if (link.links) {
        return this.renderNavDropdown(link, index);
      }

      return this.renderNavItem(link, index);
    });

    return (
      <div className={cx('Nav')} data-locator="nav">
        {linksNodes}
      </div>
    );
  }
}

export default Nav;
