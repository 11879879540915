import { PRODUCTS } from 'components/pages/SurchargePage/constants';
import { default as getPaymentData } from '../../helpers/getPaymentData';
import setPayError from '../setPayError';
import setIsPaymentInProgress from '../setIsPaymentInProgress';
import sendPayRequest from './sendPayRequest';
import createPayPolling from './createPayPolling';

const pay = () => async (dispatch, getState) => {
  const state = getState();
  const {
    surcharge: {
      common: { currentGate, surchargeId },
    },
  } = state;

  if (!currentGate) {
    dispatch(setPayError());
    return;
  }

  try {
    const paymentData = await getPaymentData(state, PRODUCTS.HOTELS);
    await dispatch(sendPayRequest(surchargeId, paymentData));
    await dispatch(createPayPolling(surchargeId));
  } finally {
    dispatch(setIsPaymentInProgress(false));
  }
};

export default pay;
