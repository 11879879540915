import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import PaymentVariants from '@ott/payment-variants';
import formatMoney from '@ott/utility-format-money';
import { lang } from '@ott/l10n';

import { setCurrentPaymentGate } from 'src/redux/modules/surcharge/actions';
import commonSelector from '../../../../selectors/commonSelector';
import { moneyFormatOptions } from '../../../../constants';

export const GateSwitcher = () => {
  const { paymentGates, currentGate } = useSelector(commonSelector);
  const dispatch = useDispatch();
  const changeGate = useCallback(
    (value) => {
      const newGate = paymentGates.find((gate) => gate.value === value);

      if (newGate) {
        dispatch(setCurrentPaymentGate(newGate));
      }
    },
    [dispatch, paymentGates]
  );

  const gates = useMemo(() => {
    return paymentGates.map((gate) => {
      const { total, currency } = gate;

      return {
        ...gate,
        text: formatMoney(total, {
          lang,
          currencyCode: currency,
          numberFormatOptions: moneyFormatOptions,
        }),
      };
    });
  }, [paymentGates]);

  if (!currentGate) {
    return null;
  }

  const { value } = currentGate;

  return (
    <PaymentVariants
      allowSingleVaraint={true}
      onChange={changeGate}
      variants={gates}
      value={value}
    />
  );
};
