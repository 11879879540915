import { all, fork, takeEvery } from 'redux-saga/effects';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import Cookie from 'js-cookie';
import _get from 'lodash/get';
import _noop from 'lodash/noop';

import { tracking as HSFTracking } from '@ott/hotels-search-form';

import metrics from 'utility/metrics';

let suggestKMParams = {};

function* hotelsTracking(action) {
  switch (action.type) {
    case HSFTracking.suggestsRequested: {
      const { requested, context } = action.meta.arg;

      const suggestID = `${Cookie.get('vid') || ''}_${context}_${Date.now()}`;
      const requestStartTime = Date.now();

      const options = {
        suggestID,
        input: requested,
        where: context,
        requestStartTime,
      };

      suggestKMParams = {
        suggestID,
        requestStartTime,
        requested,
      };

      metrics('start_hotels_suggest', options);
      break;
    }

    case HSFTracking.suggestsSuccess: {
      const { suggestID, requestStartTime } = suggestKMParams;
      const { requested, context } = action.meta.arg;
      const responseEndTime = Date.now();

      suggestKMParams.responseEndTime = responseEndTime;

      const options = {
        suggestID,
        input: requested,
        where: context,
        notEmpty: action.payload.length ? 1 : 0,
        responseTime: differenceInSeconds(responseEndTime, requestStartTime),
      };

      metrics('success_hotels_suggest', options);
      break;
    }

    case HSFTracking.suggestsFailed:
    case HSFTracking.suggestsEmpty: {
      const { suggestID, requestStartTime } = suggestKMParams;

      const errorStatusCode =
        action.type === HSFTracking.suggestsFailed
          ? _get(action, 'payload.status', 500)
          : 'Empty suggest list';

      const options = {
        errorText: errorStatusCode,
        suggestID,
        responseTime: differenceInSeconds(Date.now(), requestStartTime),
      };

      metrics('error_hotels_suggest', options);
      break;
    }

    case HSFTracking.destinationChanged: {
      const { context, ...restData } = action.eventData;
      const { selection, selectionName, selectionType, selectionOrder } = restData;
      const { requested, suggestID, responseEndTime } = suggestKMParams;

      const options = {
        selection,
        suggestID,
        input: requested,
        selectionName,
        selectionType,
        selectionOrder,
        where: context,
        timeToSelect: differenceInSeconds(Date.now(), responseEndTime),
      };

      metrics('select_hotels_suggest', options);

      suggestKMParams = {};
      break;
    }

    case HSFTracking.multiroomPopup.shown: {
      metrics('not_avail_multiroom_popup_show', {
        stage: action.eventData.context,
      });
      break;
    }
    case HSFTracking.multiroomPopup.applied: {
      metrics('not_avail_multiroom_popup_apply', {
        stage: action.eventData.context,
      });
      break;
    }
    case HSFTracking.multiroomPopup.left: {
      metrics('not_avail_multiroom_popup_leave', {
        stage: action.eventData.context,
      });
      break;
    }
    default: {
      break;
    }
  }
}

export default function* () {
  return yield all([
    fork(takeEvery, HSFTracking.suggestsRequested, hotelsTracking),
    fork(takeEvery, HSFTracking.suggestsSuccess, hotelsTracking),
    fork(takeEvery, HSFTracking.suggestsFailed, hotelsTracking),
    fork(takeEvery, HSFTracking.suggestsEmpty, hotelsTracking),
    fork(takeEvery, HSFTracking.multiroomPopup.shown, hotelsTracking),
    fork(takeEvery, HSFTracking.multiroomPopup.applied, hotelsTracking),
    fork(takeEvery, HSFTracking.multiroomPopup.left, hotelsTracking),
    fork(takeEvery, HSFTracking.destinationChanged, hotelsTracking),
  ]);
}
