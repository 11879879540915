import React from 'react';

type PictureProps = {
  source: {
    avif: string;
    webp: string;
    fallback: string;
  };
  className?: string;
  alt?: string;
  /** локатор для тестирования */
  specificLocator?: string;
};

const Picture = ({ source, className, alt = '', specificLocator = '' }: PictureProps) => (
  <picture>
    <source srcSet={source.avif} type="image/avif" />
    <source srcSet={source.webp} type="image/webp" />
    <img data-locator={specificLocator} src={source.fallback} className={className} alt={alt} />
  </picture>
);

export default Picture;
