import React from 'react';
import PropTypes from 'prop-types';

import { LinkButton as Link } from '@ott/link';

import BulletedListItemIcon from './blocks/BulletedListItemIcon';

import cls from 'classnames/bind';
import s from './BulletedListItem.scss';

const cx = cls.bind(s);

const BULLETED_LIST_ITEM_BEHAVIOR = {
  appLink: 'appLink',
};

class BulletedListItem extends React.PureComponent {
  static propTypes = {
    imageUrl: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    behavior: PropTypes.oneOf(Object.values(BULLETED_LIST_ITEM_BEHAVIOR)),
    isMobileLayout: PropTypes.bool,
  };

  static defaultProps = {};

  handleClick = () => {
    const { behavior } = this.props;
    if (behavior === BULLETED_LIST_ITEM_BEHAVIOR.appLink) {
      this.handleAppLinkClick();
    }
  };

  handleAppLinkClick = () => {
    const { isMobileLayout } = this.props;
    const appBannerElement = document.querySelector('[data-locator="app-banner"]');

    if (isMobileLayout || !appBannerElement) {
      window.open(
        'http://adj.otthyper.com/adjust/click/?network=OTT_Website&campaign=app_link_main&install_callback=',
        '_blank'
      );
      return;
    }

    appBannerElement.scrollIntoView({
      behavior: 'smooth',
    });
  };

  renderTextWithInnerAppLink = () => {
    const { text, innerAppLinkText } = this.props;

    const regex = new RegExp(innerAppLinkText, 'g');
    const link = (
      <Link onClick={this.handleAppLinkClick} className={s.innerAppLink}>
        {innerAppLinkText.replace(/\s/g, '\xa0')}
      </Link>
    );
    const contentArray = text.split(regex);

    return contentArray.map((item, i) => {
      const isLast = i === contentArray.length - 1;
      if (isLast) {
        return <span key={i}>{item}</span>;
      }

      return (
        <span key={i}>
          {item}
          {link}
        </span>
      );
    });
  };

  render() {
    const { imageUrl, title, text, className, behavior, innerAppLinkText } = this.props;

    return (
      <div
        className={cx(
          'BulletedListItem',
          {
            [`behavior--${behavior}`]: Boolean(behavior),
          },
          className
        )}
        data-locator="bulleted-list-item"
        onClick={this.handleClick}
      >
        <BulletedListItemIcon imageUrl={imageUrl} className={cx('icon')} />
        <div className={cx('textContainer')}>
          <h2 className={cx('title')} data-locator="bulleted-list-title">
            {title}
          </h2>
          <div className={cx('text')} data-locator="bulleted-list-text">
            {innerAppLinkText ? this.renderTextWithInnerAppLink() : text}
          </div>
        </div>
      </div>
    );
  }
}

export default BulletedListItem;
