import React, { useState } from 'react';
import cls from 'classnames';
import AnimateHeight from 'react-animate-height';
import ArrowIcon from '../../../../icons/ArrowIcon';
import styles from './AccordionItem.scss';

type Props = {
  title: string;
  content: string;
};

const AccordionItem = (props: Props) => {
  const { title, content }: Props = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.accordionItem}>
      <div className={styles.itemHeader} onClick={() => setIsOpen(!isOpen)}>
        <h3 className={styles.headerTitle}>{title}</h3>
        <ArrowIcon
          className={cls(styles.toggleButton, {
            [styles.toggleButtonToggled]: isOpen,
          })}
        />
      </div>
      <AnimateHeight duration={300} height={isOpen ? 'auto' : 0}>
        <div className={styles.itemContent} dangerouslySetInnerHTML={{ __html: content }} />
      </AnimateHeight>
    </div>
  );
};

export default AccordionItem;
