import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import { reducer as railwaysSearchFormReducer } from '@ott/railways-search-form';
import { suggests, heatmap } from '@ott/flights-search-form';
import { reducer as activitiesSearchReducer } from '@ott/activities-search-form';

import { reducer as carsSuggestReducer } from '@ott/cars-search-form';
import { getReducer as getSearchReducer } from '@ott/state-cars-search';

import { reducer as HSFReducer } from '@ott/hotels-search-form';

import { reducer as toursSearchFormReducer } from '@ott/tours-search-form-base';
import { reducer as toursPriceCalendarReducer } from '@ott/tours-price-calendar';
import { reducer as toursHotTours } from '@ott/hot-tours';

import { reducer as preAuth } from '@ott/pre-auth-3ds';
import { reducer as virtualCardReducer } from '@ott/shared-virtual-card-logic';

import index from './modules/index';
import common from './modules/common';
import offers from './modules/offers';
import hotelsConfig from './modules/hotelsConfig';
import surcharge from './modules/surcharge';

export default function createReducer(asyncReducers) {
  return combineReducers({
    form: formReducer,
    index,
    common,
    offers,
    surcharge,
    preAuth,
    virtualCard: virtualCardReducer,
    activities: combineReducers({ searchForm: activitiesSearchReducer }),
    avia: combineReducers({
      searchForm: combineReducers({
        suggests,
        heatmap,
      }),
    }),

    tours: combineReducers({
      searchForm: toursSearchFormReducer,
      priceCalendar: toursPriceCalendarReducer,
      hotTours: toursHotTours,
    }),

    cars: combineReducers({
      suggest: carsSuggestReducer,
      search: getSearchReducer(),
    }),

    hotels: combineReducers({
      searchForm: HSFReducer,
      hotelsConfig,
    }),

    railways: combineReducers({ ...railwaysSearchFormReducer }),
    ...asyncReducers,
  });
}
