import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames/bind';

import s from './Container.scss';

const cx = cls.bind(s);

export default function Container(props) {
  const { className, children, innerRef, ...restProps } = props;

  return (
    <div className={cx('container', className)} {...restProps} ref={innerRef}>
      {children}
    </div>
  );
}

Container.defaultProps = {
  className: '',
};

Container.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
