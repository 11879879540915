import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hot } from 'react-hot-loader';
import { scroller } from 'react-scroll';
import Button from '@ott/button';
import { Container } from '@ott/grid';
import { showAuth } from '../../../redux/modules/common/authModal/actions';
import useUpsell from '../../../hooks/useUpsell';
import styles from './HotelsBenefits.scss';

type CardsTypes = 'discounts' | 'secret-prices' | 'mobile-app';
type Props = {
  id: string;
  title: string;
  description: string;
  className?: string;
  cards: Array<{
    id: CardsTypes;
    icon: string;
    background: string;
    title: string;
    description: string;
    buttonCaption: string;
    buttonCaptionAlternate?: string;
  }>;
};

const HotelsBenefits = (props: Props) => {
  const { id, title, description, cards, className }: Props = props;
  const { data: { auth: isAuthorized = false } = {} } = useSelector((state) => state.common.auth);
  const { upsellAvailable } = useUpsell();
  const dispatch = useDispatch();

  const scrollToSearch = () => {
    scroller.scrollTo('search-form-switcher', {
      duration: 500,
      delay: 100,
      smooth: 'easeInOutQuart',
    });
  };

  const handleCardButtonClick = (cardId: CardsTypes): void  => {
    switch (cardId) {
      case 'discounts':
        if (isAuthorized) {
          scrollToSearch();
        } else {
          dispatch(showAuth());
        }
        break;
      case 'secret-prices':
        if (upsellAvailable) {
          scrollToSearch();
        } else {
          window.open('https://www.onetwotrip.com', '_self');
        }
        break;
      case 'mobile-app':
        window.open('https://www.onetwotrip.com/ru/loyalty/app', '_blank');
        break;
      default:
        break;
    }
  };

  return (
    <Container id={id} className={className}>
      <h1 className={styles.title} data-locator='hotels-benefits-title'>{title}</h1>
      <div className={styles.description} data-locator='hotels-benefits-description'>{description}</div>
      <div className={styles.cardsWrapper} data-locator='hotels-benefits-cards'>
        {cards.map((card) => {
          const buttonCaption = (
            card.id === 'discounts' && isAuthorized ||
            card.id === 'secret-prices' && !upsellAvailable
          ) ? card.buttonCaptionAlternate : card.buttonCaption;

          return (
            <div
              key={card.id}
              className={styles.card}
              style={{ backgroundImage: `url(${card.background})` }}
              data-locator={`benefit-card-${card.id}`}
            >
              <img className={styles.cardIcon} src={card.icon} alt="card-icon" data-locator={`benefit-card-${card.id}-icon`}/>
              <div className={styles.cardTitle} dangerouslySetInnerHTML={{ __html: card.title }} data-locator={`benefit-card-${card.id}-title`}/>
              <div
                className={styles.cardDescription}
                dangerouslySetInnerHTML={{ __html: card.description }}
                data-locator={`benefit-card-${card.id}-description`}
              />
              <Button className={styles.cardButton} onClick={() => handleCardButtonClick(card.id)} specificLocators={`benefit-card-${card.id}`}>
                {buttonCaption}
              </Button>
            </div>
          );
        })}
      </div>
    </Container>
  );
};

export default hot(module)(HotelsBenefits);
