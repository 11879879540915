import _get from 'lodash/get';

import { constants as creditCardConstants } from '@ott/credit-card-deprecated';
import { helpers as preAuthHelpers } from '@ott/pre-auth-3ds';

import { PAYMENT_METHODS } from 'src/components/pages/SurchargePage/constants';
import { processApplePay } from '../utils/applePay';
import getReturnUrl from './getReturnUrl';
import getRedirectUrl from './getRedirectUrl';

const getCardPayVariant = (data, processUrls, preAuthData) => {
  const { id, cardCVV } = data;
  const commonParams = {
    ...processUrls,
    ...preAuthData,
  };

  if (id) {
    // сохраненная карта
    return {
      virtualCard: {
        ...commonParams,
        id,
        cvv: cardCVV,
      },
    };
  }

  const { cardHolder, cardNumber, expMonth, expYear } = data;

  return {
    card: {
      ...commonParams,
      pan: cardNumber,
      holder: cardHolder,
      cvv: cardCVV,
      month: expMonth,
      year: expYear,
    },
  };
};

const getPaymentData = async ({ surcharge, form, preAuth }, product) => {
  const { authId } = preAuth;
  const {
    common: { currentGate, surchargeId },
  } = surcharge;

  if (!currentGate) {
    return {};
  }

  const { tag, method } = currentGate;

  const paymentData = {
    paySystem: tag,
  };

  const processUrls = {
    returnUrl: getReturnUrl(surchargeId, product),
    redirectUrl: getRedirectUrl(),
  };

  switch (method) {
    case PAYMENT_METHODS.BANK_CARD: {
      const creditCardData = _get(form, `${creditCardConstants.PAY_FORM}.values`);
      const browserData = preAuthHelpers.getDefaultBrowserData();
      const preAuthData = authId ? { authId } : { browserData };

      paymentData.payVariant = getCardPayVariant(creditCardData, processUrls, preAuthData);
      break;
    }

    case PAYMENT_METHODS.APPLE_PAY:
      paymentData.payVariant = await processApplePay(currentGate, processUrls);
      break;

    case PAYMENT_METHODS.WIDGET: {
      paymentData.continueUrl = processUrls.returnUrl;
      break;
    }

    default:
      return;
  }

  return paymentData;
};

export default getPaymentData;
