import React from 'react';
import type { Offer, BaseOfferCardProps } from '../../types';
import OfferCardLabel from '../OfferCardLabel';

export const getCardPropsFromOfferData = (data: Offer): BaseOfferCardProps => {
  const { title, description, image, label, labelIcon, labelColor, disableDimming } = data;

  return {
    title,
    subtitle: description,
    image,
    isDimmed: !disableDimming,
    labelNode: label ? (
      <OfferCardLabel value={label} image={labelIcon} isWhite={labelColor === 'white'} />
    ) : null,
  };
};
