import { createPayPolling as hotelsCreatePayPolling } from 'src/redux/modules/surcharge/actions/hotels';
import { PRODUCTS } from '../constants';

export const createPayPollingForProduct = (dispatch, product, surchargeId) => {
  switch (product) {
    case PRODUCTS.HOTELS:
      dispatch(hotelsCreatePayPolling(surchargeId));
      break;

    default:
      return null;
  }
};
