import superagent from '@ott/superagent';

import { ERRORS } from 'src/components/pages/SurchargePage/constants';

import * as T from '../../types';
import { createAviaPaymentGates } from '../../helpers/createAviaPaymentGates';
import { preparePaymentGates } from '../../utils/preparePaymentGates';
import setCurrentPaymentGate from '../setCurrentPaymentGate';
import setPaymentGates from '../setPaymentGates';

const url = '/_api/process/getpayment';

type GetPaymentResponse = {
  amount: number;
  currency: string;
  status: 'Canceled' | 'Captured' | 'WaitingForPayment' | 'Refunded' | 'Blocked';
  id: string;
  description: string;
};

export const fetchAviaSurchargeData = (surchargeId: string) => {
  return (dispatch) => {
    return dispatch({
      promise: async () => {
        try {
          const { body } = await superagent.get(`${url}/?id=${surchargeId}`);

          if (body.error) {
            return Promise.reject(ERRORS.NETWORK);
          }

          const { amount, currency, status } = body as GetPaymentResponse;

          switch (status) {
            case 'WaitingForPayment': {
              const paymentGates = createAviaPaymentGates({
                amount,
                currency,
              });
              const preparedGates = await preparePaymentGates(paymentGates);

              const [firstGate] = preparedGates;

              dispatch(setPaymentGates(preparedGates));
              dispatch(setCurrentPaymentGate(firstGate || null));

              return body;
            }
            case 'Captured':
              return Promise.reject(ERRORS.PAID_EARLY);
            // Логика взята у из легаси репозитория 12trip/src/site/js/pay.js
            case 'Canceled':
            case 'Blocked':
            case 'Refunded':
              return Promise.reject(ERRORS.CANNOT_PAY);
            default:
              return Promise.reject(ERRORS.NETWORK);
          }
        } catch (e) {
          return Promise.reject(ERRORS.NETWORK);
        }
      },
      types: [T.FETCH_SURCHARGE_DATA, T.FETCH_SURCHARGE_DATA_SUCCESS, T.FETCH_SURCHARGE_DATA_FAIL],
    });
  };
};
