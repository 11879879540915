import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { l10n } from '@ott/l10n';

import ArrowIcon from '@ott/icon-arrow';
import Lazy from '@ott/lazy';

import SimpleSlider from 'src/components/organisms/SimpleSlider';
import { Container, Row, Col } from '@ott/grid';

import styles from './Press.scss';

const NUMBER_OF_SCROLL_SLIDES = 1;

class Press extends React.PureComponent {
  static propTypes = {
    itemsToShow: PropTypes.number,
    icon: PropTypes.string,
    text: PropTypes.string,
    link: PropTypes.shape({
      href: PropTypes.string,
      title: PropTypes.string,
      target: PropTypes.string,
      rel: PropTypes.string,
    }),
  };

  static defaultProps = {
    itemsToShow: 4,
  };

  constructor(props, ctx) {
    super(props, ctx);
  }

  setSliderRef = (slider) => {
    this.slider = slider;
  };

  handleSlidePrev = () => {
    this.slider.slickPrev();
  };

  handleSlideNext = () => {
    this.slider.slickNext();
  };

  renderPressItem({ key, icon, text, link }) {
    return (
      <a key={key} className={styles.item} {...link}>
        {text && <span className={styles.text}>{text}</span>}
        <span
          className={styles.logo}
          style={{ backgroundImage: `url(${icon})` }}
          title={link.title}
        />
      </a>
    );
  }

  renderArrows() {
    const { items, itemsToShow } = this.props;

    if (items.length <= itemsToShow) {
      return null;
    }

    return (
      <div className={styles.arrows}>
        <div onClick={this.handleSlidePrev} className={cls(styles.arrow, styles['arrow--left'])}>
          <ArrowIcon fill={'#ccc'} w={9} h={16} />
        </div>
        <div onClick={this.handleSlideNext} className={cls(styles.arrow, styles['arrow--right'])}>
          <ArrowIcon fill={'#ccc'} w={9} h={16} />
        </div>
      </div>
    );
  }

  render() {
    const { className, items, itemsToShow } = this.props;

    const arrowsNode = this.renderArrows();
    const pressItemsNode = items.map(this.renderPressItem);

    return (
      <Lazy threshold={0.25}>
        <div data-locator="press" className={cls(styles.container, className)}>
          <Container>
            <Row>
              <Col desctop={12} mobile={4}>
                <div className={styles.head}>
                  <h2 className={styles.caption}>{l10n('press.caption')}</h2>
                  {arrowsNode}
                </div>
                <div className={styles.sliderWrapper}>
                  <SimpleSlider
                    ref={this.setSliderRef}
                    arrows={false}
                    dots={false}
                    slidesToShow={itemsToShow}
                    slidesToScroll={NUMBER_OF_SCROLL_SLIDES}
                  >
                    {pressItemsNode}
                  </SimpleSlider>
                </div>

                <div className={styles.pressList}>{pressItemsNode}</div>
              </Col>
            </Row>
          </Container>
        </div>
      </Lazy>
    );
  }
}

export default Press;
