import superagent from '@ott/superagent';
import { isPast } from 'date-fns';

import {
  CANNOT_PAY_STATUSES,
  ERRORS,
  SURCHARGE_STATUSES,
} from 'src/components/pages/SurchargePage/constants';

import * as T from '../../types';
import { preparePaymentGates } from '../../utils';
import setPaymentGates from '../setPaymentGates';
import setCurrentPaymentGate from '../setCurrentPaymentGate';

const url = '/_hotels_order/public/extraCharge/link';

const fetchSurchargeData = (surchargeId) => {
  return (dispatch) => {
    return dispatch({
      promise: async () => {
        try {
          const { body } = await superagent.get(`${url}/${surchargeId}`);
          const { result, error } = body;

          if (error) {
            return Promise.reject(ERRORS.NETWORK);
          }

          const { status } = result;

          if (CANNOT_PAY_STATUSES.includes(status)) {
            return Promise.reject(ERRORS.CANNOT_PAY);
          }

          if (status === SURCHARGE_STATUSES.SUCCESS) {
            return Promise.reject(ERRORS.PAID_EARLY);
          }

          const { expirationDate } = result;

          if (isPast(new Date(expirationDate))) {
            return Promise.reject(ERRORS.EXPIRED);
          }

          const { paymentGates } = result;
          const preparedGates = await preparePaymentGates(paymentGates);

          const [firstGate] = preparedGates;
          // выбираем первый способ оплаты как активный

          dispatch(setPaymentGates(preparedGates));
          dispatch(setCurrentPaymentGate(firstGate || null));

          return result;
        } catch (e) {
          return Promise.reject(ERRORS.NETWORK);
        }
      },
      types: [T.FETCH_SURCHARGE_DATA, T.FETCH_SURCHARGE_DATA_SUCCESS, T.FETCH_SURCHARGE_DATA_FAIL],
    });
  };
};

export default fetchSurchargeData;
