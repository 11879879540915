import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames/bind';

import s from './BulletedListItemIcon.scss';

const cx = cls.bind(s);

class BulletedListItemIcon extends PureComponent {
  static propTypes = {
    imageUrl: PropTypes.string.isRequired,
  };
  static defaultProps = {};

  render() {
    const { className, imageUrl } = this.props;

    const style = {
      backgroundImage: `url("${imageUrl}")`,
    };

    return (
      <div
        className={cx('BulletedListItemIcon', className)}
        style={style}
        data-locator="bulleted-list-icon"
      />
    );
  }
}

export default BulletedListItemIcon;
