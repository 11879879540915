import superagent from '@ott/superagent';
import * as T from '../types';

const url = '/_hotels/api/getHotelsConfig';

export default function getHotelsConfig(requestParams = {}) {
  return function (dispatch) {
    return dispatch({
      promise: async () => {
        const res = await superagent
          .get(url)
          .query(requestParams)
          .set('Accept', 'application/json');

        if (res && res.body && res.body.result) {
          return res.body.result;
        } else {
          return Promise.reject(res.body.error);
        }
      },
      types: [T.GET_HOTELS_CONFIG, T.GET_HOTELS_CONFIG_SUCCESS, T.GET_HOTELS_CONFIG_FAIL],
    });
  };
}
