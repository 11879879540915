import localStorage from 'local-storage';
import _times from 'lodash/times';
import PropTypes from 'prop-types';
import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useDispatch, useSelector } from 'react-redux';

import { authActions } from '@ott/auth';
import { l10n, l10nhtml } from '@ott/l10n';
import LoadingOverlay from '@ott/loading-overlay';
import Modal, { modalIcons } from '@ott/modal';

import useQuery from 'src/hooks/useQuery';
import {
  resetPayState,
  restoreCommonState,
  setSurchargeId,
} from 'src/redux/modules/surcharge/actions';

import ErrorState from './blocks/ErrorState';
import Footer from './blocks/Footer';
import Header from './blocks/Header';
import PayForm from './blocks/PayForm';
import Skeleton from './blocks/Skeleton';
import SuccessState from './blocks/SuccessState';

import errorSelector from './selectors/errorSelector';
import loadingSelector from './selectors/loadingSelector';
import pollingSelector from './selectors/pollingSelector';

import { createPayPollingForProduct, fetchSurchargeData } from './helpers';

import {
  ERRORS,
  KNOWN_PRODUCTS,
  SAVED_STATE_LS_KEY,
  SKELETON_TIME,
  SURCHARGE_STATUSES,
  THREE_DS_RETURN_QUERY_PARAM,
} from './constants';

import styles from './SurchargePage.scss';

const MONEY_BLOCK_ERRORS = [ERRORS.ORDER_CHANGE_ERROR, ERRORS.MONEY_BLOCK_CANCELLATION_ERROR];

const getErrorModeByError = (error) => {
  if (error === ERRORS.EXPIRED) {
    return 'expired';
  }

  if (error === ERRORS.ORDER_CHANGE_ERROR) {
    return 'orderChangeError';
  }

  if (error === ERRORS.MONEY_BLOCK_CANCELLATION_ERROR) {
    return 'moneyBlockCancellationError';
  }
};

const { ModalWarningIcon } = modalIcons;

const SurchargePage = ({ surchargeId, product, config }) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const isAfter3ds = useRef(false);
  const [isSkeletonShown, setIsSkeletonShown] = useState(true);

  const isPaymentInProgress = useSelector(({ surcharge }) => surcharge.process.isPaymentInProgress);
  const { surchargeDataError, payRequestError, payPollingError } = useSelector(errorSelector);
  const { surchargeDataLoading } = useSelector(loadingSelector);
  const payPolling = useSelector(pollingSelector);

  const hasPaymentError = Boolean(
    payRequestError || (payPollingError && !MONEY_BLOCK_ERRORS.includes(payPollingError))
  );
  const closeErrorModal = useCallback(() => {
    if (isAfter3ds.current) {
      window.location = window.location.pathname;
      return;
      /**
       * Если после 3ds произошла ошибка, то проще
       * перезагрузить страницу
       */
    }

    dispatch(resetPayState());
  }, [dispatch]);

  useLayoutEffect(() => {
    /**
     * Делаем небольшую задержку, чтобы избежать мелькания скелетона
     */
    setTimeout(() => setIsSkeletonShown(false), SKELETON_TIME);

    if (!surchargeId || !KNOWN_PRODUCTS.includes(product)) {
      return;
    }

    dispatch(authActions.getBasicUserInfo({ withVirtualCard: false }));
    dispatch(setSurchargeId(surchargeId));
    // Для удобного доступа

    if (THREE_DS_RETURN_QUERY_PARAM in query) {
      /**
       * Вернулись с ввода кода 3ds
       */

      isAfter3ds.current = true;

      const stateToRestore = localStorage.get(SAVED_STATE_LS_KEY);

      if (stateToRestore) {
        /**
         * Восстанавливаем стейт, который был перед походом на ACS,
         * чтобы когда пользователь вернулся, то увидел те же способы оплаты,
         * а активный тот, который он действительно выбрал
         */
        localStorage.remove(SAVED_STATE_LS_KEY);
        dispatch(restoreCommonState(stateToRestore));
      }

      createPayPollingForProduct(dispatch, product, surchargeId);
      return;
    }

    fetchSurchargeData(dispatch, product, surchargeId);
    // eslint-disable-next-line react-hooks/no-exhaustive-deps
  }, []);

  const renderContent = () => {
    if (isSkeletonShown || surchargeDataLoading) {
      /**
       * На сервере страница должна сгенерироваться по этой ветке,
       * чтобы избежать мелькания error-state
       */
      return <Skeleton />;
    }

    if (
      !surchargeId ||
      !KNOWN_PRODUCTS.includes(product) ||
      [ERRORS.CANNOT_PAY, ERRORS.NETWORK, ERRORS.EXPIRED, ...MONEY_BLOCK_ERRORS].includes(
        surchargeDataError || payPollingError
      )
    ) {
      return <ErrorState mode={getErrorModeByError(surchargeDataError || payPollingError)} />;
    }

    if (
      surchargeDataError === ERRORS.PAID_EARLY ||
      payPolling.status === SURCHARGE_STATUSES.SUCCESS
    ) {
      return <SuccessState />;
    }

    return <PayForm product={product} surchargeId={surchargeId} />;
  };

  const renderErrorDescription = () => {
    if (isAfter3ds.current) {
      return (
        <>
          <h3 className={styles.title}>{l10n('surcharge.acsError.title')}</h3>
          <div className={styles.description}>{l10nhtml('surcharge.acsError.description')}</div>
        </>
      );
    }

    if (payRequestError === ERRORS.ORDER_CANCELLED) {
      return (
        <>
          <h3 className={styles.title}>{l10n('surcharge.orderCancelledError.title')}</h3>
          <div className={styles.description}>
            {l10n('surcharge.orderCancelledError.description')}
          </div>
        </>
      );
    }

    return (
      <>
        <h3 className={styles.title}>{l10n('surcharge.payError.title')}</h3>
        <div className={styles.reasonsHeader}>{l10n('surcharge.payError.reasonsHeader')}</div>
        <ul className={styles.reasons}>
          {_times(4, (index) => (
            <li key={index}>{l10n(`surcharge.payError.reasons.${index}`)}</li>
          ))}
        </ul>
        <div className={styles.hint}>{l10n('surcharge.payError.hint')}</div>
      </>
    );
  };

  const errorModalNode = (
    <Modal isOpen={hasPaymentError} onClose={closeErrorModal}>
      <div className={styles.modalError}>
        <ModalWarningIcon />
        {renderErrorDescription()}
      </div>
    </Modal>
  );

  return (
    <div className={styles.SurchargePage}>
      <div>
        <Header className={styles.header} config={config} />
        <main className={styles.content}>{renderContent()}</main>
      </div>
      <Footer />
      {errorModalNode}
      {isPaymentInProgress && <LoadingOverlay />}
    </div>
  );
};

SurchargePage.propTypes = {
  lang: PropTypes.string.isRequired,
  product: PropTypes.string.isRequired,
  surchargeId: PropTypes.string.isRequired,
};

export default hot(module)(SurchargePage);
