import type { Offer } from './types';
import { AuthWrapper, CopyWrapper, InfoWrapper, LinkWrapper } from './ui/OfferWrapper';

const enum OffersTypes {
  DirectDiscount = 'direct_discount',
  CommonDiscount = 'common_discount',
  PersonalPromo = 'personal_promo',
  CommonPromo = 'common_promo',
  ReferralPromo = 'referral_promo',
  ReferralLink = 'referral_link',
  DynamicLink = 'dynamic_link',
  Info = 'info',
}

export const getWrapperNodeByOfferData = (offerData: Offer) => {
  /*
    Данный случай является исключением из остальных типов. Он имеет тип common_discount, а ссылка приходит action://authorize.
    Как вариант, со временем можно создать отдельный тип офера для авторизации, чтобы убрать эту часть кода.
  */
  const authLink = offerData.buttonLink?.match(/action:\/\/(.+)/i);
  if (authLink?.length && authLink[1] === 'authorize') {
    return AuthWrapper;
  }

  switch (offerData.type) {
    case OffersTypes.Info:
      return InfoWrapper;
    case OffersTypes.PersonalPromo:
    case OffersTypes.ReferralPromo:
    case OffersTypes.ReferralLink:
    case OffersTypes.DynamicLink:
    case OffersTypes.CommonPromo:
      return CopyWrapper;
    case OffersTypes.DirectDiscount:
    case OffersTypes.CommonDiscount:
    default:
      return LinkWrapper;
  }
};
