import React from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/lodash';
import { langCode } from '@ott/l10n';
import cls from 'classnames';
import styles from './ApplePay.scss';

export const ApplePay = React.memo(({ className, onClick }) => {
  return (
    <button
      className={cls(styles.ApplePay, className)}
      lang={langCode}
      onClick={onClick}
      type="button"
    />
  );
});

ApplePay.defaultProps = {
  onClick: _noop,
};

ApplePay.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};
