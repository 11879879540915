import React from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';

import { pageInfo } from 'redux/selectors/service';

import {
  form as FlightsSearchForm,
  getFlightsSearchQueryParams,
  actions,
  searchHistoryLocalStorage,
  parseRoute,
} from '@ott/flights-search-form';

import { saveSearchRouteInLocalStorage } from '@ott/search-history';

import metrics from 'utility/metrics';
import setFormReadyParam from 'utility/setFormReadyParam';
import { addDays } from 'date-fns';
import { shouldSetDefaultDateFlightSearchForm } from 'helpers/abTests';

const getDefaultStartEndFromDate = (date) => (info) => {
  const isLoading = info.initial || info.loading;
  if (!isLoading && shouldSetDefaultDateFlightSearchForm()) {
    const startDate = addDays(date, 1);
    const endDate = addDays(date, 8);
    return { startDate, endDate };
  }
  return null;
};

const getDefaultStartEndFromCurrentDate = getDefaultStartEndFromDate(new Date());

function mapStateToProps(state = {}) {
  const passengers = {
    adultsCount: 1,
    childrenAges: [],
  };

  const route = {
    directions: [],
  };

  const serviceClass = 'E';

  const {
    avia: { searchForm = {} } = {},
    common: {
      auth: { infoStatus },
    },
  } = state;
  const { heatmap: { heatmap, heatmapStatus } = {}, suggests } = searchForm;
  const { startDate: defaultStartDate, endDate: defaultEndDate } =
    getDefaultStartEndFromCurrentDate(infoStatus) || {};

  return {
    route,
    serviceClass,
    passengers,
    suggests,
    heatmap,
    heatmapStatus,
    enableInitialFormFocus: _get(state, 'customPopup.isOpen', false),
    defaultStartDate,
    defaultEndDate,
    ...pageInfo(state),
  };
}

@connect(mapStateToProps)
class FlightsSearchFormConnector extends React.PureComponent {
  constructor(props, ctx) {
    super(props, ctx);
  }

  componentDidMount() {
    setFormReadyParam('avia');
  }

  toggleTopArea(topAreaVisible) {
    // if (!this.props.isMobile) {
    //   return false;
    // }
    // this.setState({topAreaVisible});
  }

  handleFlightsSearchFormMount() {
    // if (this.props.isMobile) {
    //   document.getElementById('app').style.display = 'none';
    // }
  }

  handleFlightsSearchFormUnmount() {
    // if (this.props.isMobile) {
    //   document.getElementById('app').style.display = 'block';
    // }
  }

  handleFormSubmit = (data) => {
    metrics('start_search');

    if (data.url) {
      saveSearchRouteInLocalStorage(
        data.route,
        'historyRoutes',
        3,
        searchHistoryLocalStorage.checkNewSearchRoute
      );

      window.location.href = data.url;
      return;
    }

    const { route, query } = getFlightsSearchQueryParams(data);

    const nextQuery = {
      ...query,
      srcmarker2: 'newindex',
    };

    const queryString = Object.keys(nextQuery)
      .map((key) => `${key}=${nextQuery[key]}`)
      .join('&');

    saveSearchRouteInLocalStorage(
      {
        route: parseRoute(route),
        query,
      },
      'historyRoutes',
      3,
      searchHistoryLocalStorage.checkNewSearchRoute
    );

    window.location.href = `${this.props.urls.flightsSearch}/${route}${
      queryString ? `?${queryString}` : ''
    }`;
  };

  render() {
    const {
      route,
      serviceClass,
      passengers,
      suggests,
      heatmap,
      heatmapStatus,
      className,
      deviceInfo,
      enableInitialFormFocus,
      defaultStartDate,
      defaultEndDate,
      showBusinessTripCheckbox,
    } = this.props;

    return (
      <FlightsSearchForm
        className={className}
        route={route}
        serviceClass={serviceClass}
        passengers={passengers}
        suggests={suggests}
        heatmap={heatmap}
        heatmapStatus={heatmapStatus}
        showMonthsLabels={false}
        isMobile={false}
        deviceInfo={deviceInfo}
        toggleTopArea={this.toggleTopArea}
        actions={actions}
        onMountCallback={this.handleFlightsSearchFormMount}
        onUnmountCallback={this.handleFlightsSearchFormUnmount}
        onFormSubmit={this.handleFormSubmit}
        enableInitialFocus={enableInitialFormFocus}
        defaultStartDate={defaultStartDate}
        defaultEndDate={defaultEndDate}
        showBusinessTripCheckbox={showBusinessTripCheckbox}
      />
    );
  }
}

export default FlightsSearchFormConnector;
