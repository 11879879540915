import { createSelector } from 'reselect';
import _getFP from 'lodash/fp/get';

import { PRODUCTS_LIST, EMPTY_STRING } from 'constants/common';

const getAuth = _getFP('common.auth');
const getCustomPopup = _getFP('common.customPopup');
const getConfig = _getFP('index.config');
const getAuthModal = _getFP('common.authModal');
const getDeviceWidth = _getFP('common.viewport.width');
const getWebView = _getFP('common.viewport.webView');
const { pageInfo } = require('./service');

export default createSelector(
  (state, props) => props,
  getAuth,
  getConfig,
  getCustomPopup,
  getAuthModal,
  pageInfo,
  getDeviceWidth,
  getWebView,
  ({ history }, auth, configs, customPopup, authModal, pageInfo, deviceWidth, webView) => {
    return {
      auth,
      customPopup,
      authModal,
      pageInfo,
      deviceWidth,
      webView,
    };
  }
);
