import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router';

import compose from 'lodash/flowRight';
import _getFP from 'lodash/fp/get';

import { ActivitiesSearchForm, actions, CONSTANTS } from '@ott/activities-search-form';
import { deviceInfoObj } from '_redux/selectors/service';
import createSearchFormProps from './selectors';

import styles from './ActivitiesSearchForm.scss';
import { prepareSearchLink } from 'utility/activitiesLinks';

const getWebView = _getFP('common.viewport.webView');

const redirectCallback = (path, formQueryParams) => {
  window.location.href = prepareSearchLink(formQueryParams);
};

const mapStateToProps = (state, props) => {
  const { isWebView } = getWebView(state);

  return {
    ...createSearchFormProps(state, props),
    redirectCallback,
    page: 'index_activities',
    deviceInfo: deviceInfoObj,
    showOnlineActivities: false,
    showDefaultCities: !isWebView,
    childrenClassNames: {
      form: styles.form,
    },
  };
};

const SearchFormContainer = compose(
  withRouter,
  connect(mapStateToProps, actions),
  reduxForm({
    form: CONSTANTS.FORM_NAME,
  })
);

export default SearchFormContainer(ActivitiesSearchForm);
