import Button from '@ott/button';
import ErrorIcon from '@ott/icon-error';
import { l10n, l10nhtml, lang } from '@ott/l10n';
import React from 'react';
import styles from './ErrorState.scss';

const ERROR_TEXT = {
  expired: {
    title: l10n('surcharge.expired.title'),
    description: l10nhtml('surcharge.expired.description'),
  },
  orderChangeError: {
    title: l10n('surcharge.orderChangeError.title'),
    description: l10nhtml('surcharge.orderChangeError.description'),
  },
  moneyBlockCancellationError: {
    title: l10n('surcharge.moneyBlockCancellationError.title'),
    description: l10nhtml('surcharge.moneyBlockCancellationError.description'),
  },
  default: {
    title: l10n('surcharge.error.title'),
    description: l10nhtml('surcharge.error.description'),
  },
};

const ErrorState = ({ mode = 'default' }) => {
  const { title, description } = ERROR_TEXT[mode] || ERROR_TEXT.default;

  return (
    <div className={styles.ErrorState}>
      <div className={styles.icon}>
        <ErrorIcon />
      </div>
      <h1 className={styles.title}>{title}</h1>
      <p className={styles.description}>{description}</p>
      <Button href={`/${lang}/landings/web-support/`}>{l10n('surcharge.error.support')}</Button>
    </div>
  );
};

export default ErrorState;
