import React from 'react';
import cl from 'classnames';
import styles from './OfferCardLabel.scss';

type Props = {
  value: string;
  image?: string;
  isWhite?: boolean;
  className?: string;
};

const OfferCardLabel = ({ value, image, isWhite, className }: Props) => (
  <div className={cl(styles.container, isWhite && styles.containerWhite, className)}>
    {image && <img className={styles.icon} src={image} alt="label-emoji" />}
    <span className={styles.value}>{value}</span>
  </div>
);

export default OfferCardLabel;
