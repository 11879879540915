import T from './types';
import { withData as loader } from '@ott/utility-loader';

const initialState = {
  ...loader.initial(),
};

module.exports = function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case T.OFFERS_FETCH_INVOKE:
      return {
        ...state,
        ...loader.begin(),
      };
    case T.OFFERS_FETCH_SUCCESS:
      return {
        ...state,
        ...loader.success(action.result),
      };
    case T.OFFERS_FETCH_FAILURE:
      return {
        ...state,
        ...loader.error(action.error),
      };
    case T.OFFERS_FETCH_RESET:
      return {
        ...state,
        ...loader.initial(),
      };
    default: {
      return state;
    }
  }
};
