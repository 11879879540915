if (__CLIENT__) {
  try {
    (function(document) {
      const is = require('is_js');

      if (is.mobile() && is.ios()) {
        const headNode = document.getElementsByTagName('head')[0];
        const script = document.createElement('script');

        script.id = 'smbanner';
        script.setAttribute(
          'data-smbanner-url',
          'https://app.splitmetrics.com/smart-banners/4QRdDPv9A3Axj4rmB8VeNo/data'
        );
        script.src = 'https://dalusewymm5m7.cloudfront.net/smart-banner--locale-en.latest.js';
        script.async = 1;

        headNode.appendChild(script);
      }
    })(document);
  } catch (e) {
    console.error('Splitmetrics error: ', e);
  }
}
