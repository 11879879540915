import React from 'react';

import cls from 'classnames/bind';
import styles from './InfoPopup.scss';
const cx = cls.bind(styles);

import Modal from '@ott/modal';

export default props => {
  return (
    <Modal isOpen onClose={props.onClose} fullScreenOnMobile>
      {{
        body: (
          <div className={cx('infoPopup')}>
            <h2 className={cx('infoPopup-title')}>{props.title}</h2>
            <div className={cx('infoPopup-body')}>{props.text}</div>
            <a
              className={cx('infoPopup-link')}
              href={props.link.href}
              target={props.link.target || '_blank'}
            >
              {props.link.title}
            </a>
          </div>
        ),
      }}
    </Modal>
  );
};
