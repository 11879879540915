import React from 'react';

import TripcoinIcon from '@ott/icon-tripcoin';
import PromoIcon from './blocks/PromoIcon';

import { IS_MARKETING_PROMO_AVAILABLE } from 'src/constants/marketing';

import styles from './HotelsBadge.scss';

type HotelsBadgeProps = {
  className?: string;
  large?: boolean;
};

const enum Size {
  Small = 12,
  Large = 20,
}

const HotelsBadge = ({ className = '', large = false }: HotelsBadgeProps) => (
  <div className={className} data-locator="hotels-badge">
    {!IS_MARKETING_PROMO_AVAILABLE ? (
      <TripcoinIcon
        w={large ? Size.Large : Size.Small}
        h={large ? Size.Large : Size.Small}
        childrenClassNames={{
          circle: styles.tripcoinIconCircle,
          text: styles.tripcoinIconText,
        }}
      />
    ) : (
      <PromoIcon w={large ? Size.Large : Size.Small} h={large ? Size.Large : Size.Small} />
    )}
  </div>
);

export default HotelsBadge;
