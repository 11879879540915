import superagent from '@ott/superagent';

export const applePayMerchantIdentifiersMap = {
  payture: 'merchant.com.onetwotrip.OneTwoTrip',
  payture_old: 'merchant.com.onetwotrip.OneTwoTrip',
  sberbank: 'merchant.onetwotrip.OneTwoTrip.ApplePaySber',
};

export const APPLE_PAY_DEFAULT_PROVIDER = 'payture';
const APPLE_PAY_DEFAULT_IDENTIFIER = applePayMerchantIdentifiersMap[APPLE_PAY_DEFAULT_PROVIDER];

const getApplePayMethod = (provider = APPLE_PAY_DEFAULT_PROVIDER) => ({
  supportedMethods: 'https://apple.com/apple-pay',
  data: {
    version: 3,
    merchantIdentifier: applePayMerchantIdentifiersMap[provider] || APPLE_PAY_DEFAULT_IDENTIFIER,
    merchantCapabilities: ['supports3DS', 'supportsCredit', 'supportsDebit'],
    supportedNetworks: ['amex', 'discover', 'mastercard', 'visa'],
    countryCode: 'RU',
  },
});

const getPaymentDetails = ({ total: value, currency }) => {
  const paymentDetails = {
    total: {
      label: 'OneTwoTrip',
      amount: { value, currency },
    },
    displayItems: [],
  };

  return paymentDetails;
};

const getApplePayPaymentRequest = (total, provider) => {
  const paymentDetails = getPaymentDetails(total);
  return new PaymentRequest([getApplePayMethod(provider)], paymentDetails);
};

export const processApplePay = ({ tag: paySystem, total, currency, provider }, processUrls) => {
  return new Promise((resolve, reject) => {
    const applePayPaymentRequest = getApplePayPaymentRequest({ total, currency }, provider);

    applePayPaymentRequest.onmerchantvalidation = (event) => {
      const { validationURL } = event;

      event.complete(
        new Promise((resolvePaymentSession, rejectPaymentSession) => {
          superagent
            .post('/_pay/applePay/paymentSession/')
            .send({
              validationURL,
              paySystem,
            })
            .then(({ body: { result } }) => resolvePaymentSession(result))
            .catch(rejectPaymentSession);
        })
      );
    };

    applePayPaymentRequest
      .show()
      .then((paymentResponse) => {
        resolve({
          tokenCard: {
            token: paymentResponse.details.token.paymentData,
            ...processUrls,
          },
        });
        return paymentResponse.complete('success');
      })
      .catch(reject);
  });
};
