import { getAvailableTests } from '@ott/ab-test';

function checkABTestCookie(testVariant) {
  return getAvailableTests().includes(testVariant);
}

// https://onetwotripdev.atlassian.net/browse/AVIA-170
const defaultDateFlightSearchForm = 'a13_b';
export const shouldSetDefaultDateFlightSearchForm = () =>
  checkABTestCookie(defaultDateFlightSearchForm);
