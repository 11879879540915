import React from 'react';
import { reduxForm, Form, Field, getFormMeta, change } from 'redux-form';

import { sendMobileAppLinkSMS } from '_redux/modules/common/user/actions';
import metrics from 'utility/metrics';

import { l10n } from '@ott/l10n';

const { connect } = require('react-redux');

import Input from '@ott/input-next';
import Button from '@ott/button';
import FirstErrorShower from '@ott/first-error-shower';
import LoadingDots from '@ott/loading-dots';
import Tooltip from '@ott/tooltip';
import validate from '@ott/utility-form-validate';
import rules from '@ott/utility-form-validate-rules';

import cls from 'classnames';
import styles from './MobileAppSMSLinkForm.scss';

function mapStateToProps(state) {
  const {
    common: {
      auth = {},
      user: { sendMobileAppLinkSMSStatus },
    },
  } = state;

  // data может быть null
  const { data: authData } = auth;

  const newProps = {
    fields: getFormMeta(FORM_NAME)(state),
    isLoading: sendMobileAppLinkSMSStatus.loading,
    isSuccess: sendMobileAppLinkSMSStatus.success,
    isError: Boolean(sendMobileAppLinkSMSStatus.error),
  };

  if (authData) {
    newProps.userPhoneNumber = authData.phone;
  }

  return newProps;
}

const validateForm = validate({
  phoneNumber: [
    {
      msg: l10n('errors.required.phoneNumber'),
      validate: rules.isRequired,
    },
    {
      msg: l10n('errors.wrong.phoneNumber'),
      validate: rules.isPhone,
    },
  ],
});

const validateSync = (values, props) => {
  return validateForm(values, props);
};

const FORM_NAME = 'GetMobileAddLinkForm';
const NONE_PHONE_PAT = /([^0-9()\s-]*)/gi;
const TOOLTIP_TIMEOUT = 5000;

const formConfig = {
  form: FORM_NAME,
  validate: validateSync,
};

function clearNonPhoneSymbols(phoneNumber) {
  let result = phoneNumber;
  if (result) {
    result = result.replace(NONE_PHONE_PAT, '');
  }

  return result;
}

function parsePhoneNumber(value) {
  return clearNonPhoneSymbols(value);
}

function formatPhoneNumber(value, prevValue, cursor, state) {
  let result = clearNonPhoneSymbols(value);

  const { fields = {} } = this.props;

  const { phoneNumber: phoneNumberField = {} } = fields;

  if (result.length === 0 && !phoneNumberField.active) {
    return '';
  }

  result = result.replace(/\+/g, '');

  return `+${result}`;
}

@connect(mapStateToProps)
@reduxForm(formConfig)
class MobileAppSMSLinkForm extends React.PureComponent {
  static propTypes = {};
  static defaultProps = {};

  constructor(props, ctx) {
    super(props, ctx);
    this.state = {
      showSuccessMessage: false,
      showErrorMessage: false,
    };

    this.phoneNumberFieldProps = {
      placeholder: l10n('mobileAppBanner.phoneNumber'),
      enableAutoComplete: true,
      className: styles.phoneInput,
      parse: parsePhoneNumber.bind(this),
      format: formatPhoneNumber.bind(this),
      shouldIgnoreCursor: true,
    };

    this.disabledPhoneNumberFieldProps = {
      ...this.phoneNumberFieldProps,
      disabled: true,
    };

    this.tooltipTriggerRef = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    const { userPhoneNumber, fields = {}, dispatch } = nextProps;

    const { phoneNumber: phoneNumberField = {} } = fields;

    // если не трогали номер телефона и пришёл из auth, то подставляем его
    if (userPhoneNumber && !phoneNumberField.visited) {
      dispatch(change(FORM_NAME, 'phoneNumber', userPhoneNumber));
    }

    if (!this.props.isSuccess && nextProps.isSuccess) {
      this.setState({ showSuccessMessage: true });
      setTimeout(() => {
        this.setState({ showSuccessMessage: false });
      }, TOOLTIP_TIMEOUT);
    }

    if (!this.props.isError && nextProps.isError) {
      this.setState({ showErrorMessage: true });
      setTimeout(() => {
        this.setState({ showErrorMessage: false });
      }, TOOLTIP_TIMEOUT);
    }
  }

  handleActionSubmit = ({ phoneNumber }) => {
    const { dispatch } = this.props;

    dispatch(
      sendMobileAppLinkSMS({
        phoneNumber: phoneNumber.trim(),
        source: 'mainpage',
      })
    );

    metrics('click_mobile_banner_download_app_link');

    this.setState({
      submitTime: new Date().getTime(),
    });
  };

  renderPhoneNumberInput = ({ input, meta, ...restProps }) => {
    return (
      <Input
        {...input}
        {...meta}
        {...restProps}
        isError={meta.invalid && meta.touched}
        value={input.value}
        specificLocators="phone-number"
      />
    );
  };

  getButtonNode() {
    const { isLoading } = this.props;

    return (
      <Button
        type="submit"
        specificLocators="get-link-button"
        innerRef={this.tooltipTriggerRef}
        className={styles.getLinkButton}
        childrenClassNames={{ children: styles.buttonContent }}
        isDisabled={isLoading}
      >
        {isLoading ? <LoadingDots isWhite={true} /> : l10n('mobileAppBanner.getLink')}
      </Button>
    );
  }

  render() {
    const { className, handleSubmit, errors, isLoading, submitFailed } = this.props;

    const { submitTime, showSuccessMessage, showErrorMessage } = this.state;

    const buttonNode = this.getButtonNode();

    return (
      <Form
        className={cls(styles.MobileAppSMSLinkForm, className)}
        onSubmit={handleSubmit(this.handleActionSubmit)}
        data-locator="MobileAppSMSLinkForm"
      >
        <Field
          name="phoneNumber"
          component={this.renderPhoneNumberInput}
          props={isLoading ? this.disabledPhoneNumberFieldProps : this.phoneNumberFieldProps}
        />
        {buttonNode}
        <FirstErrorShower errors={errors} show={submitFailed} time={submitTime} container={this} />
        <Tooltip
          placement="top"
          isVisible={showSuccessMessage || showErrorMessage}
          customTargetRef={this.tooltipTriggerRef}
          specificLocator="sms-result-message"
          content={
            <>
              {showSuccessMessage && l10n('mobileAppBanner.successMessage')}
              {showErrorMessage && l10n('mobileAppBanner.errorMessage')}
            </>
          }
        />
      </Form>
    );
  }
}

export default MobileAppSMSLinkForm;
