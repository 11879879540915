import React from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';

import { Row, Col, Container } from '@ott/grid';
import { withLayoutContext } from '@ott/layout-context';

import cls from 'classnames/bind';
import s from './BulletedList.scss';

import BulletedListItem from './blocks/BulletedListItem';

const cx = cls.bind(s);

@withLayoutContext
class BulletedList extends React.PureComponent {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape(BulletedListItem.propTypes)),
  };

  static defaultProps = {
    items: [],
  };

  constructor(props, ctx) {
    super(props, ctx);
  }

  getItemsNode() {
    const { items, isMobile } = this.props;

    return items.map((item, idx) => {
      return (
        <Col key={idx} desktop={4} tablet={4} mobile={4}>
          <BulletedListItem {...item} isMobileLayout={isMobile} />
        </Col>
      );
    });
  }

  render() {
    const { className, title } = this.props;

    const itemsNode = this.getItemsNode();

    return (
      <Container data-locator="bulleted-list" className={cx('BulletedList', className)}>
        <Row>
          {title ? (
            <Col desktop={12} tablet={12} mobile={4}>
              <h1 key="title" className={cx('title')}>
                {title}
              </h1>
            </Col>
          ) : null}
          {itemsNode}
        </Row>
      </Container>
    );
  }
}

export default hot(module)(BulletedList);
