import T from './types';
import { withData as loader } from '@ott/utility-loader';

const initialState = {
  ...loader.initial(),
  data: {},
};

module.exports = function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case T.ADDITIONAL_DATA_FETCH_INVOKE:
      return {
        ...state,
        data: { ...state.data, [action.offerId]: { ...loader.begin() } },
      };
    case T.ADDITIONAL_DATA_FETCH_SUCCESS:
      return {
        ...state,
        data: { ...state.data, [action.result.offerId]: { ...loader.success(action.result) } },
      };
    case T.ADDITIONAL_DATA_FETCH_FAILURE:
      return {
        ...state,
        data: { ...state.data, [action.offerId]: { ...loader.error(action.error) } },
      };
    case T.CLEAR_ADDITIONAL_DATA_ERRORS:
      return {
        ...state,
        error: null,
      };
    default: {
      return state;
    }
  }
};
