import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames/bind';
import { l10n } from '@ott/l10n';

import GridSpreader from 'organisms/Spreader';

import s from './PaymentServices.scss';

const cx = cls.bind(s);

class PaymentServices extends React.PureComponent {
  static propTypes = {
    iconsImage: PropTypes.string,
  };

  render() {
    const { className, iconsImage } = this.props;

    return (
      <div className={cx('PaymentServices', className)}>
        <GridSpreader>
          <div>
            <h2 className={cx('caption')}>{l10n('paymentServices.caption')}</h2>
            <span className={cx('icons')} style={{ backgroundImage: `url(${iconsImage})` }} />
          </div>
        </GridSpreader>
      </div>
    );
  }
}

export default PaymentServices;
