import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { NavLink as Link } from 'react-router-dom';

import addScpParameterToUrl from '@ott/utility-scp-logic';

class NavLink extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    href: PropTypes.string.isRequired,
    className: PropTypes.string,
    isExternal: PropTypes.bool,
    isScpParameterSupported: PropTypes.bool,
    rel: PropTypes.string,
    target: PropTypes.string,
    actions: PropTypes.object,
    behavior: PropTypes.arrayOf(PropTypes.string),
    specificLocators: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  };

  static defaultProps = {};

  handleLinkClick = (evt) => {
    const { actions, behavior } = this.props;

    evt.preventDefault();
    if (actions) {
      behavior.forEach((behaviorName) => {
        if (actions[behaviorName]) {
          actions[behaviorName]();
        }
      });
    }
  };

  render() {
    const {
      className,
      children,
      href,
      isExternal,
      isScpParameterSupported,
      behavior,
      specificLocators,
      ...restAttributes
    } = this.props;

    if (behavior && behavior.length > 0) {
      return (
        <a
          onClick={this.handleLinkClick}
          className={className}
          href={href}
          data-locator={cls('nav-action-link', specificLocators)}
          {...restAttributes}
        >
          {children}
        </a>
      );
    }

    if (isExternal) {
      return (
        <a
          className={className}
          href={isScpParameterSupported ? addScpParameterToUrl(href) : href}
          data-locator={cls('nav-link', specificLocators)}
          {...restAttributes}
        >
          {children}
        </a>
      );
    }

    return (
      <Link
        to={{ pathname: href }}
        className={className}
        data-locator={cls('nav-link', specificLocators)}
        {...restAttributes}
      >
        {children}
      </Link>
    );
  }
}

export default NavLink;
