import Loadable from '@react-loadable/revised';
import InfoPopup from './InfoPopup';

import Empty from 'src/components/base/Empty';

const AsyncToursRevoPopup = Loadable({
  loader: () => import('@ott/tours-revo-popup' /* webpackChunkName: "UnsubscribeModal" */),
  loading: Empty,
});

const AsyncUnsubscribeModal = Loadable({
  loader: () => import('organisms/UnsubscribeModal' /* webpackChunkName: "UnsubscribeModal" */),
  loading: Empty,
});

const AsyncGratitudeModal = Loadable({
  loader: () => import('organisms/GratitudeModal' /* webpackChunkName: "GratitudeModal" */),
  loading: Empty,
});

export default {
  InfoPopup,
  ToursRevoPopup: AsyncToursRevoPopup,
  AsyncUnsubscribeModal,
  AsyncGratitudeModal,
};
