import is from 'is_js';
const { createStructuredSelector } = require('reselect');

export const deviceInfoObj = {
  isMobile: is.mobile(),
  isTablet: is.tablet(),
  isDesktop: is.desktop(),
  isTouchDevice: !is.desktop() && is.touchDevice(),
};

const deviceInfo = () => {
  return deviceInfoObj;
};

const pageInfo = createStructuredSelector({
  deviceInfo,
});

module.exports = {
  pageInfo,
  deviceInfoObj,
};
