import { combineReducers } from 'redux';

import { authReducer as auth } from '@ott/auth';
import { reducer as cardSuggests } from '@ott/shared-credit-cards-logic';
import { reducer as userGroups } from '@ott/shared-user-groups-logic';
import { limiter } from '@ott/superagent';
import { unconfirmedOrders } from '@ott/reservation-banner';
import user from './user';
import authModal from './authModal';
import customPopup from './customPopup';
import viewport from './viewport';
import ssrReducer from './ssr';

module.exports = combineReducers({
  limiter: limiter.reducer,
  auth,
  userGroups,
  customPopup,
  cardSuggests,
  unconfirmedOrders,
  user,
  authModal,
  viewport,
  ssr: ssrReducer,
});
