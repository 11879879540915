import React, { SVGProps } from 'react';

export const WildCashbackSimpleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="40px"
    height="54px"
    viewBox="0 0 40 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.8881 52.7957L16.9557 41.3941L11.9267 47.6334L11.9831 38.8127L8.42407 42.5L5.92395 35L0.924071 37L4.42407 19.5L16.1697 0.970403L16.9556 12.5L27.393 3.80087L29.4241 10L39.0067 4.03444L35.4241 28.5L26.2678 46.0262L25.1952 39.9758L16.8881 52.7957Z"
      fill="currentColor"
    />
  </svg>
);
