import { l10n } from '@ott/l10n';
import { PRODUCTS } from '../../../constants';

const getPaymentDescription = (product) => {
  switch (product) {
    case PRODUCTS.HOTELS:
      return l10n('surcharge.payForm.description.hotels');

    default:
      return '';
  }
};

export default getPaymentDescription;
