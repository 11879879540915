const { createStore, applyMiddleware } = require('redux');

import createSagaMiddleware from 'redux-saga';
import { batchDispatchMiddleware, enableBatching } from 'redux-batched-actions';
import { composeWithDevTools } from 'redux-devtools-extension';

const createReducer = require('./reducers');
import rootSaga from './sagas/root';

let store;
let asyncReducers = {};

module.exports = function configureStore(middleware, preloadedState) {
  let sagaMiddleware;
  if (__CLIENT__) {
    sagaMiddleware = createSagaMiddleware();

    middleware = [...middleware, sagaMiddleware, batchDispatchMiddleware];
  }

  store = createStore(
    enableBatching(createReducer()),
    preloadedState,
    composeWithDevTools(applyMiddleware(...middleware))
  );

  if (__CLIENT__) {
    window.store = store;

    sagaMiddleware.run(rootSaga);
  }

  return store;
};

module.exports.injectAsyncReducer = function(name, asyncReducer) {
  asyncReducers[name] = asyncReducer;
  store.replaceReducer(createReducer(asyncReducers));
};
