import React from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import qs from 'qs';
import {
  form as RailwaysSearchForm,
  getLastSearch,
  getSearchRoute,
  actions as searchFormActions,
} from '@ott/railways-search-form';
import { parseDateString } from '@ott/utility-date';

import getHotelsConfig from 'redux/modules/hotelsConfig/actions';

import metrics from 'utility/metrics';
import PropTypes from 'prop-types';

// not work
// in component we have different store without store.railways
// injectAsyncReducer('railways', combineReducers(reducer));

const getSearchFormValues = getFormValues('RailwaysSearchForm');

const mapStateToProps = (state) => ({ formValues: getSearchFormValues(state) });

@connect(mapStateToProps, (dispatch) => ({ dispatch }))
class RailwaysSearchFormConnector extends React.PureComponent {
  static propTypes = {
    showBusinessTripCheckbox: PropTypes.bool,
  };

  static defaultProps = {
    showBusinessTripCheckbox: false,
  };

  constructor(props, ctx) {
    super(props, ctx);
  }

  static contextTypes = require('@ott/context-utility').deviceInfoContext;

  state = {};

  getSearchData = () => {
    let search = '';
    if (typeof window !== 'undefined') {
      search = (window.location && window.location.search) || '';
    }

    const query = qs.parse(search, { ignoreQueryPrefix: true });

    this.props
      .dispatch(searchFormActions.getSearchData(query.fromCode, query.toCode))
      .then((searchData) => {
        this.setState({
          initialValues: {
            ...searchData,
            roundTrip: false,
            dates: { startDate: parseDateString(query.date) },
            adults: Number(query.adults || 0),
            children: Number(query.children || 0),
            totalChildren: query.totalChildren || [],
            infants: Number(query.infants || 0),
          },
        });
      });
  };

  async componentWillMount() {
    let search = '';
    if (typeof window !== 'undefined') {
      search = (window.location && window.location.search) || '';
    }

    const query = qs.parse(search, { ignoreQueryPrefix: true });

    if (query.fromCode && query.toCode) {
      this.getSearchData();
    } else {
      this.setState({
        initialValues: getLastSearch(),
      });
    }
  }

  componentDidMount() {
    const {
      utility,
      utility: {
        gaEvent: { gaEvent, clearDimensions },
      },
    } = require('@ott/shared-railways-logic');

    clearDimensions();

    gaEvent({
      category: 'rzdIndex',
      action: 'indexShow',
      value: {
        nonInteraction: true,
      },
    });

    const { isMobile } = this.context.deviceInfo;

    utility.KM('RZD_INDEX_SHOW', {
      obj: {
        isMobile,
      },
    });

    const { dispatch } = this.props;
    dispatch(utility.pageView('index'));
    dispatch(utility.intentMedia('train.home'));
    dispatch(getHotelsConfig({ lang: 'ru', locale: 'ru' }));
  }

  handleFormSubmit = (data) => {
    const { formValues, dispatch } = this.props;

    const { utility } = require('@ott/shared-railways-logic');

    metrics('start_search', {
      product: 'poezda',
    });

    dispatch(
      utility.intentMedia(
        'train.home',
        {
          ...formValues,
          ...data,
        },
        true
      )
    );

    const searchUrl = getSearchRoute(data);
    window.location.href = `${this.props.urls.railwaysSearch}${searchUrl}`;

    // const { isMobile } = this.context.deviceInfo;
    // if (utility.hotelsPopunder.isOpenHotelsPopunder(formValues, data, isMobile)) {
    //   utility.hotelsPopunder.openHotelsPopunder(searchUrl, formValues, data);
    // } else {
    //   window.location.href = `${this.props.urls.railwaysSearch}${searchUrl}`;
    // }
  };

  render() {
    const { initialValues } = this.state;
    const { showBusinessTripCheckbox } = this.props;

    if (!initialValues) {
      return null;
    }

    return (
      <RailwaysSearchForm
        onFormSubmit={this.handleFormSubmit}
        initialValues={initialValues}
        showBusinessTripCheckbox={showBusinessTripCheckbox}
      />
    );
  }
}

export default RailwaysSearchFormConnector;
