import { hotels as hotelsActions, avia as aviaActions } from 'redux/modules/surcharge/actions';

import { FETCH_SURCHARGE_DATA_FAIL } from 'redux/modules/surcharge/types';
import { PRODUCTS } from '../constants';

const { fetchSurchargeData: fetchHotelsSurchargeData } = hotelsActions;
const { fetchAviaSurchargeData } = aviaActions;

export const fetchSurchargeData = (dispatch, product, surchargeId) => {
  switch (product) {
    case PRODUCTS.HOTELS:
      return dispatch(fetchHotelsSurchargeData(surchargeId));
    case PRODUCTS.AVIA:
      return dispatch(fetchAviaSurchargeData(surchargeId));
    default:
      dispatch({ type: FETCH_SURCHARGE_DATA_FAIL });
      return Promise.reject();
  }
};
