import React from 'react';
import { l10nhtml } from '@ott/l10n';
import styles from './Footer.scss';

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer className={styles.Footer}>
      <p className={styles.copyright}>{l10nhtml('surcharge.footer.copyright', { year })}</p>
    </footer>
  );
};

export default Footer;
