import _get from 'lodash/get';

const paySelector = ({ surcharge, common }) => {
  return {
    isLoadingUserInfo: _get(common, 'auth.infoStatus.loading'),
    paymentCardInfo: common.cardSuggest,
    paymentGates: surcharge.common.paymentGates,
    currentGate: surcharge.common.currentGate,
  };
};

export default paySelector;
