const getBusKmParams = (params) => {
  const { departureId, destinationId } = params;

  return {
    departureId,
    destinationId,
  };
};

export default getBusKmParams;
