import React from 'react';
import type { Offer } from './types';
import { getWrapperNodeByOfferData } from './helpers';
import metrics from 'utility/metrics';

const metricsClick = ({ id, name }: { id: string; name: string }) => {
  const metricsData = { offer_id: id, offer_name: name };
  metrics('main_offers_click', metricsData);
};

type Props = {
  data: Offer;
};

const OfferCard = ({ data, ...props }: Props) => {
  const WrapperNode = getWrapperNodeByOfferData(data);

  const handleOfferClick = () => {
    metricsClick({ id: data.id, name: data.name });
  };

  return <WrapperNode onClick={handleOfferClick} data={data} {...props} />;
};

export default OfferCard;
