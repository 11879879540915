import { PRODUCTS } from 'src/components/pages/SurchargePage/constants';
import { startPayment as hotelsStartPayment } from '../actions/hotels';
import { startPayment as aviaStartPayment } from '../actions/avia';

const getStartPaymentActionCreator = (product) => {
  switch (product) {
    case PRODUCTS.HOTELS:
      return hotelsStartPayment;
    case PRODUCTS.AVIA:
      return aviaStartPayment;

    default:
      return () => {};
  }
};

export default getStartPaymentActionCreator;
