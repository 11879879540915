import superagent from '@ott/superagent';
import { ERRORS, API_ERRORS } from 'components/pages/SurchargePage/constants';
import * as T from '../../types';

const url = '/_hotels_order/public/extraCharge/pay';

export default (surchargeId, data) => {
  return {
    types: [T.PAY_REQUEST, T.PAY_REQUEST_SUCCESS, T.PAY_REQUEST_FAIL],
    propagateError: true,
    promise: async () => {
      try {
        const { body } = await superagent.post(`${url}/${surchargeId}`).send(data);

        return body.result;
      } catch (error) {
        return Promise.reject(
          error?.response?.body?.error === API_ERRORS.ORDER_CANCELLED
            ? ERRORS.ORDER_CANCELLED
            : ERRORS.NETWORK
        );
      }
    },
  };
};
