import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BridgerPay from '@ott/bridger-pay';
import usePreviousValue from 'src/hooks/usePreviousValue';

import { resetPayState } from 'src/redux/modules/surcharge/actions';
import { createPayPollingForProduct } from 'src/components/pages/SurchargePage/helpers';
import pollingSelector from 'src/components/pages/SurchargePage/selectors/pollingSelector';

type Props = {
  onClick: VoidFunction
  product: string;
  surchargeId: string;
}

type CashierData = {
  cashierKey: string;
  cashierToken: string;
};

export const BridgerPayment = ({ onClick, product, surchargeId }: Props) => {
  const dispatch = useDispatch();
  const payPolling = useSelector(pollingSelector);
  const prevPayPolling = usePreviousValue(payPolling);
  const orderProcessingResolver = useRef<((value: CashierData) => void) | null>(null);

  useEffect(() => {
    if (payPolling.isBridgerPay && !prevPayPolling.isBridgerPay && orderProcessingResolver?.current) {
      const { postParams } = payPolling as ({ postParams: CashierData });
      orderProcessingResolver?.current(postParams);
    }
  });

  const getCashierData = async () => {
    const shouldContinuePayment: CashierData = await new Promise((resolve) => {
      orderProcessingResolver.current = resolve;
      onClick();
    });

    orderProcessingResolver.current = null;

    return shouldContinuePayment;
  }

  const handlePaid = () => {
    createPayPollingForProduct(dispatch, product, surchargeId);
  }

  const abortPayment = () => {
    dispatch(resetPayState());
  }

  return (
    <BridgerPay
      getCashierData={getCashierData}
      onPaid={handlePaid}
      onDeclined={abortPayment}
      onPaymentClose={abortPayment}
    />
  )
}
