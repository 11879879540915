import React, { SVGProps } from 'react';

export const WildCashbackIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 102 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.5012 100L30.3463 79.8576L25.2278 91.6165L42.9475 74.5865L37.729 97.0677L46.3525 85.1571V90.3451L61.5681 74.5865L56.3495 97.0677L80.9173 69.9799L102 16.3534L84.9187 31.5414L94.6794 0L82.4673 23.8789L84.1801 15.1606L68.4183 29.4177L76.0589 0L54.0596 36.7293L58.1516 19.2857L46.3525 37.594L50.8311 2.93233L19.878 36.7293L0 94.5489L17.7197 77.5188L12.5012 100Z"
      fill="currentColor"
    />
  </svg>
);
