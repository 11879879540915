import * as T from '../types';

const initialState = {
  isPaymentInProgress: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case T.SET_IS_PAYMENT_IN_PROGRESS: {
      return {
        ...state,
        isPaymentInProgress: action.isPaymentInProgress,
      };
    }

    default:
      return state;
  }
};
