const T = require('./types');
import cloneDeep from 'lodash/cloneDeep';

const initialState = {};

module.exports = function reducer(state = initialState, action) {
  switch (action.type) {
    case T.UPDATE: {
      const { config } = action;

      const nextState = cloneDeep({
        ...state,
        ...config,
      });

      return nextState;
    }
    default: {
      return state;
    }
  }
};
