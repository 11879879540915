import _identity from 'lodash/identity';
import { l10n } from '@ott/l10n';
import {
  PAYMENT_METHODS,
  PAYMENT_METHODS_ORDER,
} from 'src/components/pages/SurchargePage/constants';

const getOrder = (orderingArray) => (value) => {
  const index = orderingArray.indexOf(value);
  // элементы с неизвестными ключами в конец
  return index === -1 ? Infinity : index;
};

const sortBySortedKeys = (array, orderingArray, itemToComparableValue = _identity) => {
  const order = getOrder(orderingArray);
  return array.sort((a, b) => {
    return order(itemToComparableValue(a)) - order(itemToComparableValue(b));
  });
};

export const preparePaymentGates = async (serverGates) => {
  const checks = (serverGates || []).map((gate, index) => {
    const { paymentMethod: method, tag, currency, total, provider } = gate;

    const commonParams = {
      method,
      tag,
      currency,
      total,
      value: `${tag}@${index}`,
    };

    switch (method) {
      case PAYMENT_METHODS.BANK_CARD: {
        return Promise.resolve({
          ...commonParams,
          title: l10n('surcharge.payForm.paymentMethod.card'),
          icons: ['visa', 'mastercard', 'mir'],
        });
      }
      case PAYMENT_METHODS.WIDGET: {
        return Promise.resolve({
          ...commonParams,
          title: l10n('surcharge.payForm.paymentMethod.card'),
          icons: ['mastercard', 'visa'],
        });
      }

      case PAYMENT_METHODS.APPLE_PAY: {
        if (
          typeof window === 'undefined' ||
          !('ApplePaySession' in window) ||
          !('PaymentRequest' in window)
        ) {
          return Promise.reject(null);
        }

        if (ApplePaySession.canMakePayments()) {
          return Promise.resolve({
            ...commonParams,
            title: 'Apple Pay',
            icons: ['applePay'],
            provider,
          });
        }

        /**
         * TODO: перейти на canMakePaymentsWithActiveCard API
         * https://developer.apple.com/documentation/apple_pay_on_the_web/applepaysession/1778000-canmakepaymentswithactivecard
         */

        return Promise.reject(null);
      }

      default:
        return Promise.reject(null);
    }
  });

  const filteredGates = (await Promise.allSettled(checks)).reduce(
    (result, { status, value }) => (status === 'fulfilled' ? [...result, value] : result),
    []
  );
  return sortBySortedKeys(filteredGates, PAYMENT_METHODS_ORDER, (gate) => gate.method);
};
