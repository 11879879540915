const testDomainRegExp = /^m$|^local$|^alpha|^beta|^staging|^sandbox|^production|^www$/i;

export const getOTTBrandDomain = () => {
  let hostname = '';

  if (typeof window !== 'undefined') {
    hostname = window.location.hostname;
  }

  const domain3 = hostname.split('.').reverse()[2];

  if (!domain3) {
    return null;
  }

  if (testDomainRegExp.test(domain3)) {
    return null;
  }

  return domain3;
};

export const isOttBrand = (): boolean => Boolean(getOTTBrandDomain());
