import React from 'react';

import ActivitiesInNearestCity from 'organisms/ActivitiesInNearestCity';
import ActivitiesInPopularCities from 'organisms/ActivitiesInPopularCities';
import { LikesMergeObserver } from '@ott/activities-widget';
import BulletedList from 'organisms/BulletedList';
import CarProviders from 'organisms/CarProviders';
import Deals from 'organisms/DealsWrapper';
import Feedback from 'organisms/Feedback';
import MarkBanner from 'organisms/MarkBanner';
import MobileAppBanner from 'organisms/MobileAppBanner';
import MobileAppBannerNew from 'organisms/MobileAppBannerNew';
import OffersSlider from 'organisms/OffersSlider';
import PaymentServices from 'organisms/PaymentServices';
import Press from 'organisms/Press';
import SearchFormSwitcher from 'organisms/SearchFormSwitcher';
import SeoLinks from 'organisms/SeoLinks';
import SeoOptions from 'organisms/SeoOptions';
import SeoText from 'organisms/SeoText';
import SurchargePage from 'pages/SurchargePage';
import Title from 'layouts/Title';
import ToursHotToursWrapper from 'organisms/ToursHotToursWrapper';
import ToursPriceCalendarWrapper from 'organisms/ToursPriceCalendarWrapper';
import HotelsBenefits from 'organisms/HotelsBenefits';
import NewOffersSlider from 'organisms/NewOffersSlider';
import HotelsSeoLinks from 'organisms/HotelsSeoLinks';
import VirtualCardBanner from 'organisms/VirtualCardBanner';
import MetaWebBanner from 'organisms/MetaWebBanner';

// компоненты должны быть export default hot(module)(ComponentName)
const map = {
  markBanner: MarkBanner,
  formSwitcher: SearchFormSwitcher,
  offersSlider: OffersSlider,
  newOffersSlider: NewOffersSlider,
  bulletedList: BulletedList,
  mobileAppBanner: MobileAppBanner,
  mobileAppBannerNew: MobileAppBannerNew,
  deals: Deals,
  feedback: Feedback,
  press: Press,
  seoOptions: SeoOptions, // 404
  seoText: SeoText,
  seoLinks: SeoLinks,
  paymentServices: PaymentServices,
  surchargePage: SurchargePage,
  toursPriceCalendarWrapper: ToursPriceCalendarWrapper,
  toursHotToursWrapper: ToursHotToursWrapper,
  hotelsBenefits: HotelsBenefits,
  hotelsSeoLinks: HotelsSeoLinks,
  virtualCardBanner: VirtualCardBanner,
  title: Title,
  nearestActivities: ActivitiesInNearestCity,
  activitiesInPopularCities: ActivitiesInPopularCities,
  activitiesLikesMergeObserver: LikesMergeObserver,
  carProviders: CarProviders,
  metaWebBanner: MetaWebBanner,
};

export default map;
