import React from 'react';
import { useSelector } from 'react-redux';
import { CreditCardWithForm } from '@ott/credit-card-deprecated';
import commonSelector from '../../../../selectors/commonSelector';
import { PRODUCTS } from '../../../../constants';

const PRODUCTS_WHERE_CARD_HOLDER_IS_REQUIRED = [PRODUCTS.HOTELS, PRODUCTS.AVIA];

export const CreditCard = ({ onSubmit, product }) => {
  const { paymentCardInfo, isLoadingUserInfo } = useSelector(commonSelector);
  const isCardHolderRequired = PRODUCTS_WHERE_CARD_HOLDER_IS_REQUIRED.includes(product);

  const shouldDisableSavedCard = product === PRODUCTS.AVIA;

  return (
    <CreditCardWithForm
      shouldDisableSavedCards={shouldDisableSavedCard}
      onSubmit={onSubmit}
      paymentCardInfo={paymentCardInfo}
      isLoadingUserInfo={isLoadingUserInfo}
      isCardHolderRequired={isCardHolderRequired}
      enableSaveCard={false}
    />
  );
};
