import React from 'react';
import cl from 'classnames';
import styles from './BaseOfferCard.scss';
import { BaseOfferCardProps } from '../../types';

const BaseOfferCard = ({
  title,
  subtitle,
  labelNode,
  actionNode,
  image,
  isDimmed,
  className,
}: BaseOfferCardProps) => {
  const backgroundImageValue = isDimmed
    ? `linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)), url(${image})`
    : `url(${image})`;

  return (
    <article
      className={cl(styles.container, className)}
      style={{ backgroundImage: backgroundImageValue }}
    >
      <div className={styles.labelWrapper}>{labelNode}</div>

      <div className={styles.textWrapper}>
        <h3 className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
        <p className={styles.description} dangerouslySetInnerHTML={{ __html: subtitle }} />
      </div>

      <div className={styles.actionWrapper}>{actionNode}</div>
    </article>
  );
};

export default BaseOfferCard;
