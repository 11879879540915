import _get from 'lodash/get';
import { withData as loader } from '@ott/utility-loader';

import * as T from './types';

const initialState = {
  ...loader.initial({ features: {} }),
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case T.GET_HOTELS_CONFIG: {
      return {
        ...initialState,
        ...loader.begin(),
      };
    }

    case T.GET_HOTELS_CONFIG_SUCCESS: {
      const features = _get(action, 'result.features', {});
      const multiroom = _get(action, 'result.multiroom', {});
      const upsell = _get(action, 'result.upsell', {});
      const resellerName = _get(action, 'result.resellerName');
      const offersExpireTime = _get(action, 'result.ttl.token');

      return {
        ...state,
        ...loader.success({
          features,
          multiroom,
          upsell,
          offersExpireTime,
          resellerName,
        }),
      };
    }

    case T.GET_HOTELS_CONFIG_FAIL: {
      return {
        ...initialState,
        ...loader.error(),
      };
    }

    default:
      return state;
  }
};
