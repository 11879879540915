import React, { forwardRef } from 'react';
import styles from './ArrowButton.scss';
import ChevronIcon from 'src/components/icons/ChevronIcon';
import cls from 'classnames';

type ButtonProps = React.ComponentPropsWithoutRef<'button'> & { direction: 'left' | 'right' };

const ArrowButton = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => (
  <button
    className={cls(styles.button, { [styles.nextButton]: props.direction === 'right' })}
    ref={ref}
    {...props}
  >
    <ChevronIcon direction={props.direction} />
  </button>
));

export default ArrowButton;
