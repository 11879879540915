import React from 'react';
import PropTypes from 'prop-types';

const up = 'M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z';

function getAngle(direction) {
  switch (direction) {
    case 'right':
      return 90;
    case 'down':
      return 180;
    case 'left':
      return 270;
    default:
      return 0;
  }
}

function getStyle(props) {
  const angle = getAngle(props.direction);

  const style = props.style || {};

  return {
    transform: `rotate(${angle}deg)`,
    ...style,
  };
}

const ChevronIcon = props => (
  <svg className={props.className} fill={props.fill} style={getStyle(props)} viewBox="0 0 24 24">
    <path d={up} />
  </svg>
);

ChevronIcon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  direction: PropTypes.oneOf(['up', 'down', 'left', 'right']),
};

export default ChevronIcon;
