import React from 'react';
import cl from 'classnames';
import styles from './OfferCardAction.scss';
import Tooltip from '@ott/tooltip';

type Props = {
  tooltipContent?: string;
  isTooltipOpened?: React.ReactNode;
  className?: string;
  children?: React.ReactNode;
};

const OfferCardAction = ({ isTooltipOpened, tooltipContent, className, children }: Props) => (
  <Tooltip
    triggerClassName={styles.tooltipTrigger}
    content={tooltipContent}
    isVisible={isTooltipOpened}
  >
    <span role="button" className={cl(styles.container, className)}>
      {children}
    </span>
  </Tooltip>
);

export default OfferCardAction;
