import React, { useState, useEffect } from 'react';

import { Container } from '@ott/grid';
import MarketingBanner from '@ott/marketing-banner';

import useUpsell from 'src/hooks/useUpsell';

import styles from './MarkBanner.scss';

const MarkBanner = ({ product, adUnitId, queryParams }: typeof MarketingBanner.Props) => {
  const [isMounted, setIsMounted] = useState(false);
  const { upsellAvailable } = useUpsell();

  // задержка чтоб успел дернуться hotelsConfig
  useEffect(() => {
    setTimeout(() => {
      setIsMounted(true);
    }, 1000);
  }, []);

  if (!isMounted) {
    return null;
  }

  return (
    <Container className={styles.bannerContainer}>
      <MarketingBanner
        product={product}
        adUnitId={adUnitId}
        queryParams={{ ...queryParams, show_hotels_discount: upsellAvailable }}
      />
    </Container>
  );
};

export default MarkBanner;
