import qs from 'qs';
import { lang } from '@ott/l10n';

const getCurrentSearchParams = () =>
  typeof window !== 'undefined'
    ? qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
      })
    : {};

const getSearchString = (params) => {
  return qs.stringify(
    {
      s: true,
      ...getCurrentSearchParams(),
      ...params,
    },
    {
      addQueryPrefix: true,
    }
  );
};

/**
 * Вертикали находятся в других репозиториях и лежат на других портах.
 * Для тестирования удобнее вести на прод.
 * */
const devHost = __DEV__ ? '//www.onetwotrip.com' : '';

const basicPath = `${devHost}/${lang}/activities`;

export const prepareActivityLink = (activityId, params) => {
  return `${basicPath}/${activityId}/${getSearchString(params)}`;
};

export const prepareSearchLink = (params) => {
  return `${basicPath}/search/${getSearchString(params)}`;
};
