import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames/bind';
import ArrowIcon from '@ott/icon-select-arrow';
import Overlay from '@ott/overlay';

import s from './NavDropdown.scss';

const cx = cls.bind(s);

class NavDropdown extends React.PureComponent {
  static propTypes = {
    items: PropTypes.array.isRequired,
    onToggleShow: PropTypes.func.isRequired,
    renderOption: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    isOpen: PropTypes.bool,
    specificLocators: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  };

  static defaultProps = {};

  constructor(props, ctx) {
    super(props, ctx);
  }

  setRef = ref => {
    this._containerRef = ref;
  };

  getRef = () => {
    return this._containerRef;
  };

  handleToggleShow = () => {
    const { onToggleShow, index } = this.props;

    onToggleShow(index);
  };

  renderTrigger() {
    const { title, isOpen } = this.props;

    return (
      <div key="trigger" className={cx('trigger')} onMouseDown={this.handleToggleShow}>
        {title}
        <ArrowIcon
          className={cx('trigger-icon', isOpen && 'trigger-icon--flip')}
          fill="#fff"
          w={10}
          h={6}
        />
      </div>
    );
  }

  renderOverlay() {
    const { items, renderOption, isOpen } = this.props;

    if (!isOpen) {
      return null;
    }

    const linksNode = items.map(renderOption);

    return (
      <Overlay
        show
        targetFn={this.getRef}
        placement="bottom"
        containerPadding={24}
        isCentering={false}
      >
        <div className={cx('overlay')}>{linksNode}</div>
      </Overlay>
    );
  }

  render() {
    const { className, specificLocators } = this.props;

    return (
      <div
        ref={this.setRef}
        className={cx('NavDropdown', className)}
        data-locator={cx('nav-dropdown', specificLocators)}
      >
        {this.renderTrigger()}
        {this.renderOverlay()}
      </div>
    );
  }
}

export default NavDropdown;
