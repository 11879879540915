import React from 'react';
import AccordionItem from './blocks/AccordionItem';

type Props = {
  className?: string,
  data: Array<{
    key: number;
    title: string;
    content: string;
  }>;
};

const Accordion = (props: Props) => {
  const { className, data = [] }: Props = props;

  return (
    <div className={className}>
      {data.map((item) => (
        <AccordionItem key={item.key} title={item.title} content={item.content} />
      ))}
    </div>
  );
};

export default Accordion;
