import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames/bind';

import s from './Col.scss';

const cx = cls.bind(s);

export default function Col(props) {
  const { desktop, tablet, mobile, children, className, forwardedRef, ...restProps } = props;

  return (
    <div
      ref={forwardedRef}
      className={cx(
        'column',
        `column-desktop-${desktop}`,
        `column-tablet-${tablet}`,
        `column-mobile-${mobile}`,
        className
      )}
      {...restProps}
    >
      {children}
    </div>
  );
}

export const ColWithRef = React.forwardRef((props, ref) => {
  return <Col {...props} forwardedRef={ref} />;
});

Col.defaultProps = {
  desktop: 0,
  tablet: 0,
  mobile: 0,
  className: '',
};

Col.propTypes = {
  desktop: PropTypes.number,
  tablet: PropTypes.number,
  mobile: PropTypes.number,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.oneOfType([() => null, PropTypes.elementType]),
    }),
  ]),
};
