import React from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { lang } from '@ott/l10n';

import { withRouter } from 'react-router';
import _compose from 'lodash/fp/compose';
import { CarsSearchFormWithContainer, withCarSearchInitialValues } from '@ott/cars-search-form';

import styles from './CarsSearchForm.scss';

const CarsSearchFormWrapper = _compose(
  withRouter,
  withCarSearchInitialValues
)(CarsSearchFormWithContainer);

const CarSearchFormContainer = ({ urls }) => {
  const handleSearch = (routerParams) => {
    window.location.href = `${urls.carsSearch}/search?${qs.stringify(routerParams)}`;
  };

  return <CarsSearchFormWrapper className={styles.container} lang={lang} onSearch={handleSearch} />;
};

CarSearchFormContainer.propTypes = {
  className: PropTypes.string,
};

export default CarSearchFormContainer;
