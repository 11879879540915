export const DATA_API_URL = '/_mark/offers/buyer';
export const PROMOCODE_API_URL = '/_mark/offers/';

export const COLORS_MAP = {
  white: 'offer--white',
  yellow: 'offer--yellow',
  blue: 'offer--blue',
};

export const DEFAULT_COLOR = 'white';
export const DARK_COLORS = ['blue'];

export const CARD_ACTIONS = {
  COPY_DATA: 'copydata',
  FETCH_DATA: 'fetchdata',
  NO_ACTION: 'noaction',
  LINK: 'link',
};

export const OFFERS_TYPES = {
  direct_discount: 'direct_discount',
  common_discount: 'common_discount',
  personal_promo: 'personal_promo',
  common_promo: 'common_promo',
  referral_promo: 'referral_promo',
  referral_link: 'referral_link',
  dynamic_link: 'dynamic_link',
};

export const OFFERS_SOURCE = {
  LOCAL: 'local',
  REMOTE: 'remote',
};

export const EXCLUDE_OFFERS_NAMES = ['SPECIAL_HOTELS'];

export const NOT_AUTHORIZED_BID = 'not_authorized';
