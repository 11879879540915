import Cookie from 'js-cookie';

import { intlPolyfill } from '@ott/utility-intl-polyfill';
import { lang, REACT_INTL_LOCALE_MAPPING } from '@ott/l10n';

import trackError from 'utility/trackError';

function initTrackJs() {
  let trackJsAppKey = document.documentElement.getAttribute('data-trackjs-app-key');

  if (__WHITE_LABEL__) {
    // на доменах партнера не работают инструкции nginx
    trackJsAppKey = 'index-wl';
  }

  if (!trackJsAppKey) {
    return;
  }

  // eslint-disable-next-line no-underscore-dangle
  global._trackJs = {
    token: '9434784669a9411185e8a512654b173a',
    application: trackJsAppKey,
    userId: Cookie.get('vid') || '',
  };

  import('trackjs');
}

if (__PROD__) {
  initTrackJs();
}

function blockAdFoxYandexPixel() {
  try {
    const srcPropertyDescriptor = Object.getOwnPropertyDescriptor(Image.prototype, 'src');

    const oldSrcSet = srcPropertyDescriptor && srcPropertyDescriptor.set;

    if (oldSrcSet) {
      Object.defineProperty(Image.prototype, 'src', {
        ...srcPropertyDescriptor,
        set(value) {
          // запрещаем грузить пиксель Яндекса
          if (value && value.includes('an.yandex.ru/mapuid/yandex')) {
            return;
          }
          oldSrcSet.call(this, value);
        },
      });
    }
  } catch (err) {
    trackError(err);
  }
}

blockAdFoxYandexPixel();

(async function () {
  try {
    await intlPolyfill(REACT_INTL_LOCALE_MAPPING[lang]);

    require('./client');
  } catch (e) {
    trackError(e);
  }
})();
