export const createAviaPaymentGates = ({
  amount,
  currency,
}: {
  amount: number;
  currency: string;
}) => {
  return [
    {
      provider: 'payture_otk_egw_web',
      currency,
      total: amount,
      tag: 'payture_otk_egw_web',
      paymentMethod: 'bank_card',
    },
  ];
};
