import React from 'react';
import cls from 'classnames/bind';
import PropTypes from 'prop-types';

import s from './Row.scss';

const cx = cls.bind(s);

export default function Row(props) {
  const { children, className, ...restProps } = props;
  return (
    <div className={cx('row', className)} {...restProps}>
      {children}
    </div>
  );
}

Row.defaultProps = {
  className: '',
};

Row.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
