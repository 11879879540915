import React from 'react';
import styles from './OfferWrapper.scss';
import { OfferWrapperProps } from '../../types';
import OfferCardAction from '../OfferCardAction';
import BaseOfferCard from '../BaseOfferCard';
import { getCardPropsFromOfferData } from './helpers';

const LinkWrapper = ({ data, ...props }: OfferWrapperProps) => {
  const cardProps = getCardPropsFromOfferData(data);

  return (
    <a
      className={styles.container}
      href={data.buttonLink}
      target="_blank"
      rel="noreferrer"
      {...props}
    >
      <BaseOfferCard
        {...cardProps}
        actionNode={
          <OfferCardAction className={styles.cardAction}>{data.buttonText}</OfferCardAction>
        }
      />
    </a>
  );
};

export default LinkWrapper;
