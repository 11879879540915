import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import localStorage from 'local-storage';

import _get from 'lodash/get';
import Cookie from 'js-cookie';
import { lang } from '@ott/l10n';

import DfpBanner, { getBannerOptions } from '@ott/gdn-banner';

import styles from './DownloadAppBanner.scss';

const DownloadAppBanner = ({
  bannerHiddenCookieName,
  auth: { infoStatus: authInfoStatus },
  auth,
}) => {
  const bannerHiddenCookie = localStorage.get(bannerHiddenCookieName);
  const [isBannerHiddenManually, setBannerHiddenManually] = useState(false);
  const isAuthLoaded = !authInfoStatus.initial && !authInfoStatus.loading;
  const userStatus = _get(auth, 'data.userStatus.type', 'guest');

  const handlePageUnload = () => {
    localStorage.set(bannerHiddenCookieName, true);
  };

  if (typeof window !== 'undefined') {
    useEffect(() => {
      window.addEventListener('beforeunload', handlePageUnload);
      return () => {
        window.removeEventListener('beforeunload', handlePageUnload);
      };
    }, []);
  }

  const handleFrameMessageReceived = (messageEvt) => {
    if (
      !(
        messageEvt.origin === 'http://tpc.googlesyndication.com' ||
        messageEvt.origin === 'https://tpc.googlesyndication.com'
      ) ||
      typeof messageEvt.data !== 'object' ||
      !messageEvt.data.cmd
    ) {
      return;
    }

    switch (messageEvt.data.cmd) {
      case 'collapseBanner': {
        let expires;
        switch (userStatus) {
          case 'pro':
            expires = 30;
            break;
          default:
            expires = 182;
            break;
        }
        localStorage.set(bannerHiddenCookieName, true);
        setBannerHiddenManually(true);
        window.removeEventListener('message', handleFrameMessageReceived);
        break;
      }
      default:
        break;
    }
  };

  const handleDfpBannerRender = () => {
    if (typeof window !== 'undefined') {
      window.addEventListener('message', handleFrameMessageReceived, false);
    }
  };

  if (bannerHiddenCookie || isBannerHiddenManually || !isAuthLoaded) {
    return null;
  }

  const bannerOptionsArray = [
    { key: 'userstatus', value: userStatus },
    { key: 'lang', value: lang },
  ];

  const referrerCookie = Cookie.get('referrer');
  if (referrerCookie) {
    bannerOptionsArray.push({
      key: 'source',
      value: referrerCookie,
    });
  }

  return (
    <div className={styles.bannerWrapper}>
      <DfpBanner
        containerId="div-gpt-ad-1594039181666-0"
        bannerId="/21734590017/App_Download"
        size={{ type: 'fluid' }}
        lang={[lang]}
        bannerOptions={getBannerOptions(bannerOptionsArray)}
        onRender={handleDfpBannerRender}
      />
    </div>
  );
};

DownloadAppBanner.propTypes = {
  bannerHiddenCookieName: PropTypes.string,
  auth: PropTypes.object,
};

DownloadAppBanner.defaultProps = {
  bannerHiddenCookieName: 'isDownloadOttAppBannerHidden',
  auth: {},
};

export default memo(DownloadAppBanner);
