import React from 'react';
import { useSelector } from 'react-redux';
import { hot } from 'react-hot-loader';
import Deals from '@ott/deals';
import metrics from 'utility/metrics';
import getBusKmParams from './kmParams/getBusKmParams';

const DealsWrapper = (props) => {
  const {
    config: { product },
    banner,
    className,
    ...restProps
  } = props;
  const auth = useSelector((state) => state.common.auth);

  const trackClick = (_, itemParams) => {
    const getVerticalKmParams = () => {
      switch (product) {
        case 'bus':
          return getBusKmParams(itemParams);
        default:
          return null;
      }
    };

    metrics('deals_click', {
      page: `index_${product}`,
      ...getVerticalKmParams(),
    });
  };

  const getAdditionalBannerOptions = () => {
    const userStatus = auth?.data?.userStatus?.type;
    return {
      userstatus: [`userstatus_${userStatus}`],
    };
  };

  if (banner) {
    banner.bannerProps.bannerOptions = {
      ...banner.bannerProps.bannerOptions,
      ...getAdditionalBannerOptions(),
    };
  }

  return <Deals banner={banner} trackClick={trackClick} className={className} {...restProps} />;
};

export default hot(module)(DealsWrapper);
