import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { Row, Col, Container } from '@ott/grid';

import { useDebounce } from 'helpers/hooks';

import styles from './CarProviders.scss';

const providerListFirstPart = [
  'https://static.onetwotrip.com/images/cars/providers/europcar.svg',
  'https://static.onetwotrip.com/images/cars/providers/sixt.svg',
  'https://static.onetwotrip.com/images/cars/providers/hertz.svg',
];

const providerListSecondPart = [
  'https://static.onetwotrip.com/images/cars/providers/alamo.svg',
  'https://static.onetwotrip.com/images/cars/providers/avis.svg',
  'https://static.onetwotrip.com/images/cars/providers/goldcar.svg',
];

const CarProviders = () => {
  const [logosLoaded, needLoadingLogos] = useState(false);

  const [containerRef, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  useDebounce(
    () => {
      if (inView && !logosLoaded) {
        needLoadingLogos(true);
      }
    },
    100,
    [inView]
  );

  const renderProviderImage = (carProvider, index) => (
    <img key={index} className={styles.image} src={carProvider} alt="" />
  );

  return (
    <Container innerRef={containerRef} className={styles.container} data-locator="car-providers">
      {logosLoaded && (
        <Row>
          <Col className={styles.col} mobile={4} tablet={6} desktop={6}>
            {providerListFirstPart.map(renderProviderImage)}
          </Col>
          <Col className={styles.col} mobile={4} tablet={6} desktop={6}>
            {providerListSecondPart.map(renderProviderImage)}
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default CarProviders;
