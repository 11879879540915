const loadingSelector = ({ surcharge, preAuth }) => {
  const {
    surchargeData: { loading: surchargeDataLoading },
    payRequest: { loading: payRequestLoading },
    payPolling: { loading: payPollingLoading },
  } = surcharge;

  const {
    status: { loading: preAuthLoading },
  } = preAuth;

  return { payRequestLoading, surchargeDataLoading, preAuthLoading, payPollingLoading };
};

export default loadingSelector;
