import T from './types';

const defaultState = {
  width: null,
  webView: {
    isWebView: null,
    device: null,
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case T.UPDATE_VIEWPORT: {
      return {
        ...state,
        width: action.width,
      };
    }
    case T.SET_WEB_VIEW: {
      return {
        ...state,
        webView: action.webView,
      };
    }
    default:
      return state;
  }
};
