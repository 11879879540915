import React from 'react';

type Props = {
  className?: string;
  width?: number;
  height?: number;
  fill?: string;
};

const ArrowIcon = (props: Props) => {
  const {
    className,
    width = 24,
    height = 24,
    fill = '#212121',
}: Props = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      className={className}
    >
      <path
        fill={fill}
        fillRule="evenodd"
        d="m12 13.94 5.47-5.47a.75.75 0 0 1 1.06 1.06l-6 6a.748.748 0 0 1-1.06 0l-6-6a.75.75 0 0 1 1.06-1.06L12 13.94Z"
      />
    </svg>
  );
};

export default ArrowIcon;
