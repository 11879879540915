import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames/bind';
import noop from 'lodash/noop';
import Slider from 'react-slick';

import s from './SimpleSlider.scss';

import './SimpleSlider.scss.external';
import ArrowIcon from '../../icons/ArrowIcon';

const cx = cls.bind(s);

class SimpleSlider extends React.PureComponent {
  static propTypes = {
    alignDotsLeft: PropTypes.bool,
    setRef: noop,
  };

  static defaultProps = {
    alignDotsLeft: true,
  };

  constructor() {
    super();
    this.state = { index: 0, mounted: false };
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  setRef = (ref) => {
    const { setRef } = this.props;
    this.slider = ref;

    if (setRef) {
      setRef(ref);
    }
  };

  handleBeforeChange = (oldIndex, nextIndex) => {
    this.setState({ index: nextIndex });
  };

  renderLeftArrow() {
    const { arrows } = this.props;
    const { index } = this.state;

    if (!arrows) {
      return null;
    }

    if (!index) {
      // иначе отобразится arrow по умолчанию
      return <span />;
    }

    return (
      <div className={cx('relative')}>
        <div className={cx('arrow', 'arrow--left')}>
          <ArrowIcon />
        </div>
      </div>
    );
  }

  renderRightArrow() {
    const { arrows, children, slidesToShow = 0 } = this.props;
    const { index } = this.state;

    if (!arrows) {
      return null;
    }

    if (children && children.length <= index + slidesToShow) {
      return <span />; // иначе отобразится arrow по умолчанию
    }

    return (
      <div className={cx('relative')}>
        <div className={cx('arrow', 'arrow--right')}>
          <ArrowIcon />
        </div>
      </div>
    );
  }

  render() {
    const { children, alignDotsLeft, ...rest } = this.props;
    const { mounted } = this.state;

    return (
      <div className={mounted ? '' : 'slider-unmounted'}>
        <Slider
          ref={this.setRef}
          beforeChange={this.handleBeforeChange}
          dots={true}
          dotsClass={`slick-dots ${alignDotsLeft ? s['dots--left'] : ''}`}
          infinite={true}
          arrows={false}
          prevArrow={this.renderLeftArrow()}
          nextArrow={this.renderRightArrow()}
          {...rest}
        >
          {children}
        </Slider>
      </div>
    );
  }
}

export default SimpleSlider;
