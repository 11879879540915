import React from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import { Provider } from 'react-redux';

import VCProvider from './components/service/VCProvider';
import App from './components/layouts/App';

const Root = ({ store }) => {
  if (!store) {
    return null;
  }

  return (
    <Provider store={store}>
      <VCProvider>
        <App />
      </VCProvider>
    </Provider>
  );
};

Root.propTypes = {
  store: PropTypes.shape(),
};

export default hot(module)(Root);
