import React from 'react';
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader';

import cls from 'classnames/bind';
import styles from './ToursHotToursWrapper.scss';
const cx = cls.bind(styles);

import HotTours from '@ott/hot-tours';
import Spreader from 'organisms/Spreader';

import trackKM from 'utility/tours/trackKM';
import trackGA from 'utility/trackGA';

@connect(state => {
  const {
    tours: { hotTours },
    common: { viewport },
  } = state;

  return {
    hotTours,
    viewport,
  };
})
class ToursHotToursWrapper extends React.PureComponent {
  trackEvent = (type, data) => {
    const gaObj = {
      eventLabel: null,
      eventAction: 'click',
      eventCategory: 'tr_index',
    };

    const kmObj = {
      event: null,
    };

    switch (type) {
      case 'moreClick':
        {
          gaObj.eventLabel = 'hot_deals_show_more';
          kmObj.event = 'TR_INDEX_HOT_DEALS_SHOW_MORE';
        }
        break;

      case 'changeSlide':
        {
          gaObj.eventLabel = 'hot_deals_swipe';
          kmObj.event = 'TR_INDEX_HOT_DEALS_SWIPE';
        }
        break;

      case 'cardClick':
        {
          gaObj.eventLabel = 'hot_deals_click';

          kmObj.event = 'TR_INDEX_HOT_DEALS_CLICK';
          kmObj.data = data;
        }
        break;
    }

    if (gaObj.eventLabel) {
      trackGA(gaObj);
    }

    if (kmObj.event) {
      trackKM(kmObj.event, kmObj.data);
    }
  };

  render() {
    const { hotTours, viewport, className } = this.props;

    return (
      <Spreader className={cx('ToursHotToursWrapper', className)}>
        <h2 className={cx('title')}>Горящие туры</h2>

        <HotTours
          viewportWidth={viewport.width}
          hotTours={hotTours}
          showImages={true}
          trackEvent={this.trackEvent}
        />
      </Spreader>
    );
  }
}

export default hot(module)(ToursHotToursWrapper);
