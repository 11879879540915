import React from 'react';
import styles from './MockOffers.scss';
import cls from 'classnames';
import { useLayoutContext } from '@ott/layout-context';

const MOBILE_MOCKS = Array.from(Array(1));
const DESKTOP_MOCKS = Array.from(Array(4));
const MockOffers = () => {
  const { isMobile } = useLayoutContext();
  const mockArray = isMobile ? MOBILE_MOCKS : DESKTOP_MOCKS;

  const MockCard = () => (
    <div className={styles.offerContainer}>
      <div className={cls(styles.offer, styles['offer--mock'], styles['offer--small'])}>
        <div className={styles.mockHeaderPart}>
          <div className={cls(styles.mockIcon, styles.mockGradient)}>&nbsp;</div>
        </div>
        <div className={styles.mockContentPart}>
          <div className={styles.mockTextContent}>
            <div className={cls(styles.mockTitle, styles.mockGradient)}>&nbsp;</div>
            <div className={cls(styles.mockDescriptionLong, styles.mockGradient)}>&nbsp;</div>
            <div className={cls(styles.mockDescriptionShort, styles.mockGradient)}>&nbsp;</div>
          </div>
          <div className={cls(styles.mockAction, styles.mockGradient)}>&nbsp;</div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.mockCardsContainer}>
      {mockArray.map((_, i) => (
        <MockCard key={i} />
      ))}
    </div>
  );
};

export default MockOffers;
