import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _get from 'lodash/fp/get';

import superagent from '@ott/superagent';
import ActivitiesWidget from '@ott/activities-widget';
import { Container } from '@ott/grid';
import { l10n } from '@ott/l10n';

import { prepareActivityLink, prepareSearchLink } from 'utility/activitiesLinks';
import { showAuth } from 'src/redux/modules/common/authModal/actions';

import styles from './ActivitiesInNearestCity.scss';

class ActivitiesInNearestCity extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
  };

  static defaultProps = { className: '' };

  constructor(props) {
    super(props);

    this.state = { nearestCity: null };
  }

  componentDidMount() {
    this.fetchNearestCityInfo();
  }

  fetchNearestCityInfo = () => {
    superagent
      .get('/_api/ipinfo/get/')
      .then(this.getNearCityIataCode)
      .then(this.saveCityCode)
      .catch((error) => {
        console.error('Get IP info error:', error);
      });
  };

  saveCityCode = (code) =>
    new Promise((resolve, reject) => {
      if (code) {
        this.setState({ nearestCity: code }, () => resolve(code));
      } else {
        reject();
      }
    });

  getNearCityIataCode = _get('body.nearCityCode');

  render() {
    const { nearestCity } = this.state;
    const { className, openAuth } = this.props;

    if (!nearestCity) {
      return null;
    }

    return (
      <Container className={className}>
        <ActivitiesWidget
          className={styles.widget}
          openAuth={() => openAuth()}
          prepareCustomActivityLink={prepareActivityLink}
          prepareCustomSearchLink={prepareSearchLink}
          searchParams={{ iata: nearestCity }}
          shouldUseExternalLinks={true}
          specificLocators="activities-in-nearest-city-block"
          title={l10n('activities.titles.nearest')}
          trackId="nearest-city"
          trackPage="activities-index"
        />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openAuth: () => dispatch(showAuth()),
  };
};

export default connect(null, mapDispatchToProps)(ActivitiesInNearestCity);
