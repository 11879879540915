import { SET_SSR } from './types';

type Action = {
  type: string;
  payload: boolean;
};

const ssrReducer = (state = true, action: Action) => {
  if (action.type === SET_SSR) {
    return action.payload;
  }

  return state;
};

export default ssrReducer;
