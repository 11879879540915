import React from 'react';
import PropTypes from 'prop-types';

function BadgeNewMobileIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className={className}
    >
      <defs />
      <g fill="none" fillRule="evenodd">
        <circle cx="10" cy="10" r="10" fill="#FFD41E" />
        <path
          fill="#212121"
          fillRule="nonzero"
          d="M3.4 12.9V9.5a1 1 0 011-.6c.3 0 .5 0 .6.2l.2.7v3.1h1V9.7C6.3 8.6 5.8 8 4.8 8c-.5 0-1 .2-1.4.6v-.5h-1v4.8h1zm6 0c.5 0 .9 0 1.2-.2.3-.1.6-.3.7-.6l-.5-.6c-.3.4-.8.6-1.2.6-.4 0-.7 0-1-.3-.2-.3-.3-.6-.3-1h3.1v-.4c0-.8-.2-1.4-.5-1.8-.4-.4-.9-.6-1.5-.6a2 2 0 00-2 1.2l-.2 1.3v.1c0 .7.2 1.3.6 1.7.5.5 1 .7 1.7.7zm1-2.9H8.3c0-.3.2-.6.3-.8.2-.2.5-.3.8-.3.3 0 .5 0 .7.2l.3.9zM14 13l1-3.3 1 3.3h.9l1.3-4.8h-1l-.8 3.3-1-3.3h-.8l-1 3.3-.8-3.3h-1l1.3 4.8h.9z"
        />
      </g>
    </svg>
  );
}

BadgeNewMobileIcon.propTypes = {
  className: PropTypes.string,
};

export default BadgeNewMobileIcon;
