import React, { useEffect, useState } from 'react';
import { OfferWrapperProps } from '../../types';
import copyToClipboard from 'src/utility/copyToClipboard/copyToClipboard';
import { getCardPropsFromOfferData } from './helpers';
import { getAdditionalOffersData } from '../../hooks/useOffersData';
import BaseOfferCard from '../BaseOfferCard';
import OfferCardAction from '../OfferCardAction';
import LoadingDots from '@ott/loading-dots';
import styles from './OfferWrapper.scss';

const enum TooltipLabel {
  PromoCodeCopied = 'Промокод скопирован',
  LinkCopied = 'Ссылка скопирована',
  CantCopy = 'Не удалось скопировать',
}

const CopyWrapper = ({ data, onClick, ...props }: OfferWrapperProps) => {
  const [isLoading, setLoading] = useState(false);
  const [label, setLabel] = useState('');
  const [tooltipContent, setTooltipContent] = useState<TooltipLabel>(TooltipLabel.PromoCodeCopied);
  const [isTooltipOpened, setTooltipOpened] = useState(false);

  const cardProps = getCardPropsFromOfferData(data);

  const handleCopyClick = async () => {
    if (onClick) {
      onClick();
    }

    if (label && label !== data.buttonText) {
      copyToClipboard(label);
      setTooltipOpened(true);

      return null;
    }

    setLoading(true);
    try {
      const response = await getAdditionalOffersData({ id: data.id });
      if (response?.promo) {
        setLabel(response.promo);
        setTooltipContent(TooltipLabel.PromoCodeCopied);
        copyToClipboard(response.promo);
      } else if (response?.buttonLink) {
        setTooltipContent(TooltipLabel.LinkCopied);
        copyToClipboard(response.buttonLink);
      } else {
        setTooltipContent(TooltipLabel.CantCopy);
      }
      setTooltipOpened(true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let timeout: number;

    if (isTooltipOpened) {
      timeout = window.setTimeout(() => {
        setTooltipOpened(false);
      }, 1500);
    }

    return () => clearTimeout(timeout);
  }, [isTooltipOpened]);

  return (
    <button className={styles.container} type="button" onClick={handleCopyClick} {...props}>
      <BaseOfferCard
        {...cardProps}
        actionNode={
          <OfferCardAction
            className={styles.cardAction}
            isTooltipOpened={isTooltipOpened}
            tooltipContent={tooltipContent}
          >
            {isLoading ? <LoadingDots isWhite={true} /> : label || data.buttonText}
          </OfferCardAction>
        }
      />
    </button>
  );
};

export default CopyWrapper;
