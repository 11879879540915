import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { Container } from '@ott/grid';
import { l10n } from '@ott/l10n';
import { useLayoutContext } from '@ott/layout-context';
import Button from '@ott/button';
import Picture from '../Picture';
import styles from './MobileAppBannerNew.scss';

type PictureType = {
  avif: string;
  webp: string;
  fallback: string;
};

type Props = {
  id: string;
  className?: string;
  images: {
    qrCode: string;
    ottLogo: PictureType;
    phone: PictureType;
    gradient: PictureType;
  };
  webView: {
    device?: string | null;
    isWebView: boolean | null;
  };
};

const MobileAppBannerNew = (props: Props) => {
  const {
    id,
    className,
    images,
    webView: { isWebView },
  }: Props = props;
  const [isMounted, setIsMounted] = useState(false);
  const { isDesktop } = useLayoutContext();
  const storeIcons = {
    desktop: {
      appleStore: 'https://static.onetwotrip.com/images/index/mobileApp/app_store_desktop.svg',
      googlePlay: 'https://static.onetwotrip.com/images/index/mobileApp/google_play_desktop.svg',
      appGallery: 'https://static.onetwotrip.com/images/index/mobileApp/app_gallery_desktop.svg',
      ruStore: 'https://static.onetwotrip.com/images/index/mobileApp/ru_store_desktop.svg',
    },
    mobile: {
      appleStore: 'https://static.onetwotrip.com/images/index/mobileApp/app_store.svg',
      googlePlay: 'https://static.onetwotrip.com/images/index/mobileApp/google_play.svg',
      appGallery: 'https://static.onetwotrip.com/images/index/mobileApp/app_gallery.svg',
      ruStore: 'https://static.onetwotrip.com/images/index/mobileApp/ru_store.svg',
    },
  };

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (isWebView) {
    return null;
  }

  const pathSegments = typeof window !== 'undefined' ? window.location.pathname.split('/') : [];
  const locale = pathSegments[1] || 'ru';

  return (
    <Container id={id} className={className}>
      <div className={styles.wrapper} data-locator="app-banner">
        <Picture
          source={images.phone}
          className={styles.phone}
          alt=""
        />
        <Picture
          source={images.gradient}
          className={styles.gradient}
          alt=""
        />
        <div className={styles.content}>
          <div className={styles.title} data-locator="mobile-app-title">
            {l10n('mobileAppBannerNew.title')}
          </div>
          <div className={styles.description} data-locator="mobile-app-description">
            {isDesktop
              ? l10n('mobileAppBannerNew.description')
              : l10n('mobileAppBannerNew.descriptionMobile')}
          </div>
          {isMounted && isDesktop ? (
            <div className={styles.iconsWrapper}>
              <div className={styles.storesIcons}>
                <img
                  className={styles.storeIcon}
                  src={storeIcons.desktop.appleStore}
                  alt="apple-store"
                  data-locator="ios-app-link-desktop"
                />
                <img
                  className={styles.storeIcon}
                  src={storeIcons.desktop.googlePlay}
                  alt="google-play"
                  data-locator="android-app-link-desktop"
                />
                <img
                  className={styles.storeIcon}
                  src={storeIcons.desktop.appGallery}
                  alt="app-gallery"
                  data-locator="huawei-app-link-desktop"
                />
                <img
                  className={styles.storeIcon}
                  src={storeIcons.desktop.ruStore}
                  alt="ru-store"
                  data-locator="rustore-app-link-desktop"
                />
              </div>
              <span className={styles.note} data-locator="mobile-app-note-text">
                {l10n('mobileAppBannerNew.note')}
              </span>
            </div>
          ) : (
            <div className={styles.downloadWrapper}>
              <Button
                className={styles.downloadButton}
                specificLocators="download-mobile-app"
                href={`https://12trip.onelink.me/CGkl?pid=adaptive_mobile&c=first_page&af_channel=ott_website&af_adset=${locale}`}
              >
                {l10n('mobileAppBannerNew.buttonCaptionMobile')}
              </Button>
              <div className={styles.storesIcons}>
                <img
                  className={styles.storeIcon}
                  src={storeIcons.mobile.appleStore}
                  alt="apple-store"
                  data-locator="ios-app-link-mobile"
                />
                <img
                  className={styles.storeIcon}
                  src={storeIcons.mobile.googlePlay}
                  alt="google-play"
                  data-locator="android-app-link-mobile"
                />
                <img
                  className={styles.storeIcon}
                  src={storeIcons.mobile.appGallery}
                  alt="app-gallery"
                  data-locator="huawei-app-link-mobile"
                />
                <img
                  className={styles.storeIcon}
                  src={storeIcons.mobile.ruStore}
                  alt="ru-store"
                  data-locator="rustore-app-link-mobile"
                />
              </div>
            </div>
          )}
        </div>
        {isDesktop ? (
          <div className={styles.qrCodeWrapper} data-locator="desktop-view-image">
            <img src={images.qrCode} alt="qr-code" data-locator="desktop-qr-code" />
          </div>
        ) : (
          <Picture
            source={images.ottLogo}
            className={styles.image}
            specificLocator="mobile-view-image"
            alt="ott-logo"
          />
        )}
      </div>
    </Container>
  );
};

export default hot(module)(MobileAppBannerNew);
