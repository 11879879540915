import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { submit as submitReduxForm } from 'redux-form';

import Button from '@ott/button';
import { constants as creditCardConstants } from '@ott/credit-card-deprecated';
import PreAuth from '@ott/pre-auth-3ds';
import formatMoney from '@ott/utility-format-money';
import { l10n, lang } from '@ott/l10n';

import {
  getPayActionCreator,
  getReturnUrl,
  getStartPaymentActionCreator,
} from 'src/redux/modules/surcharge/helpers';

import { ApplePay } from './blocks/ApplePay';
import { CreditCard } from './blocks/CreditCard';
import { BridgerPayment } from './blocks/BridgerPayment';
import { GateSwitcher } from './blocks/GateSwitcher';
import { Form3ds } from './blocks/Form3ds';

import commonSelector from '../../selectors/commonSelector';
import pollingSelector from '../../selectors/pollingSelector';
import { surchargeDataSelector } from '../../selectors/surchargeDataSelector';

import { getPaymentDescription } from './helpers';

import { KNOWN_PAYMENT_METHODS, moneyFormatOptions, PAYMENT_METHODS } from '../../constants';

import styles from './PayForm.scss';

const PayForm = ({ product, surchargeId }) => {
  const dispatch = useDispatch();
  const { currentGate } = useSelector(commonSelector);
  const payPolling = useSelector(pollingSelector);
  const surchargeData = useSelector(surchargeDataSelector);
  const startPaymentActionCreator = getStartPaymentActionCreator(product);
  const pay = () => dispatch(startPaymentActionCreator());
  const submit = () => dispatch(submitReduxForm(creditCardConstants.PAY_FORM));

  const termUrl = getReturnUrl(surchargeId, product);

  const renderForm3ds = () => {
    if (!payPolling) {
      return null;
    }

    const { is3ds, postParams, isOld3DS } = payPolling;

    if (!is3ds) {
      return null;
    }

    return <Form3ds postParams={postParams} isOld3DS={isOld3DS} termUrl={termUrl} />;
  };

  if (!currentGate) {
    return null;
  }

  const { method, total, currency } = currentGate;

  const submitByMethod = {
    [PAYMENT_METHODS.APPLE_PAY]: <ApplePay onClick={pay} />,
    [PAYMENT_METHODS.WIDGET]: (
      <BridgerPayment onClick={pay} product={product} surchargeId={surchargeId} />
    ),
    [PAYMENT_METHODS.BANK_CARD]: (
      <Button type="button" withFullWidth={true} onClick={submit}>
        {l10n('surcharge.payForm.pay')}
      </Button>
    ),
  };

  const formByMethod = {
    [PAYMENT_METHODS.APPLE_PAY]: null,
    [PAYMENT_METHODS.WIDGET]: null,
    [PAYMENT_METHODS.BANK_CARD]: <CreditCard product={product} onSubmit={pay} />,
  };

  const payActionCreator = getPayActionCreator(product);
  const totalText = formatMoney(total, {
    lang,
    currencyCode: currency,
    numberFormatOptions: moneyFormatOptions,
  });
  const { description } = surchargeData ?? {};
  const paymentDescription = description ?? getPaymentDescription(product);

  return (
    <div>
      <h1 className={styles.title}>{l10n('surcharge.payForm.title')}</h1>
      <div className={styles.description}>{paymentDescription}</div>
      <GateSwitcher />
      {KNOWN_PAYMENT_METHODS.includes(method) && (
        <>
          <div className={styles.formContainer}>{formByMethod[method]}</div>
          <div className={styles.totalContainer}>
            <div>{l10n('surcharge.payForm.total')}</div>
            <div>{totalText}</div>
          </div>
          <div className={styles.submitContainer}>{submitByMethod[method]}</div>
        </>
      )}
      {renderForm3ds()}
      <PreAuth onPay={payActionCreator} />
    </div>
  );
};

export default PayForm;
