import superagent from '@ott/superagent';
import T from './../types';
import { DATA_API_URL, EXCLUDE_OFFERS_NAMES } from 'constants/offers';
import trackError from 'utility/trackError';

export default function fetchOffers(params = {}) {
  return function (dispatch) {
    return dispatch({
      promise: async () => {
        const res = await superagent
          .get(DATA_API_URL)
          .query({
            fullOffers: true,
            exclude: EXCLUDE_OFFERS_NAMES,
            useCache: true,
            ...params,
          })
          .set('Accept', 'application/json');

        if (res && res.body && res.body.result) {
          const { errors, ...restResult } = res.body.result;

          if (errors && errors.offers) {
            const { msg } = errors.offers;
            let errorObj = { error: 'STATS_ERROR' };
            try {
              errorObj = JSON.parse(msg);
            } catch (err) {
              trackError(err);
            }

            return Promise.reject({
              ...restResult,
              ...errorObj,
            });
          }

          return Promise.resolve({ ...restResult });
        } else {
          return Promise.reject(res.body.error);
        }
      },
      types: [T.OFFERS_FETCH_INVOKE, T.OFFERS_FETCH_SUCCESS, T.OFFERS_FETCH_FAILURE],
    });
  };
}
