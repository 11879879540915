import React from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import { withLayoutContext } from '@ott/layout-context';

import { Container, Row, Col } from '@ott/grid';
import MobileView from './blocks/MobileView';
import DesktopView from './blocks/DesktopView';

@withLayoutContext
class MobileAppBanner extends React.PureComponent {
  state = {
    isMount: false,
  };

  static propTypes = {
    className: PropTypes.string,
    bannerImage: PropTypes.shape({
      mobile: PropTypes.string,
      desktop: PropTypes.string,
    }),
    config: PropTypes.object,
    webView: PropTypes.object,
  };

  static defaultProps = {};

  constructor(props, ctx) {
    super(props, ctx);
  }

  componentDidMount() {
    this.setState({
      isMount: true,
    });
  }

  render() {
    const {
      className,
      bannerImage = {},
      config: { apps },
      webView,
      isMobile,
      isDesktop,
    } = this.props;

    const { isMount } = this.state;

    if (webView.isWebView || !isMount) {
      return null;
    }

    return (
      <Container className={className}>
        <Row>
          {isMobile && (
            <Col desktop={0} tablet={0} mobile={4}>
              <MobileView image={bannerImage.mobile} apps={apps} />
            </Col>
          )}
          {isDesktop && (
            <Col desktop={12} tablet={12} mobile={0}>
              <DesktopView image={bannerImage.desktop} apps={apps} />
            </Col>
          )}
        </Row>
      </Container>
    );
  }
}

export default hot(module)(MobileAppBanner);
