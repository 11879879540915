import React from 'react';

import styles from './Skeleton.scss';

const Skeleton = () => {
  return (
    <div>
      <div className={styles.title} />
      <div className={styles.subtitle} />
      <div className={styles.form} />
      <div className={styles.submit} />
    </div>
  );
};

export default Skeleton;
