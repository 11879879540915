import _get from 'lodash/get';
import localStorage from 'local-storage';

import { actions as preAuthActions } from '@ott/pre-auth-3ds';
import { constants as creditCardConstants } from '@ott/credit-card-deprecated';
import { helpers as preAuthHelpers } from '@ott/pre-auth-3ds';

import { PAYMENT_METHODS, SAVED_STATE_LS_KEY } from 'components/pages/SurchargePage/constants';
import { default as getPayActionCreator } from '../../helpers/getPayActionCreator';
import { default as getReturnUrl } from '../../helpers/getReturnUrl';
import { default as setPayError } from '../setPayError';
import { default as setIsPaymentInProgress } from '../setIsPaymentInProgress';

const prepareCardForPreAuth = (data) => {
  const { id, cardCVV } = data;

  if (id) {
    return {
      virtualCard: {
        id,
        cvv: cardCVV,
      },
    };
  }

  const { cardNumber, expMonth, expYear, cardHolder } = data;

  return {
    card: {
      pan: cardNumber,
      cvv: cardCVV,
      month: expMonth,
      holder: cardHolder,
      year: expYear,
    },
  };
};

const startPayment = () => (dispatch, getState) => {
  const { surcharge, form } = getState();
  const {
    common: { currentGate, paymentGates, surchargeId },
  } = surcharge;

  if (!currentGate) {
    dispatch(setPayError());
    return;
  }

  const { method, tag } = currentGate;

  /**
   * На весь процесс оплаты устанавливаем флаг inProgress в true.
   * По этому флагу показываем лоадер, потому что отправку формы в банк
   * мы отследить не можем, а банк иногда тупит
   *
   * Снимается флаг уже после ответа от поллинга в actions вертикали
   */
  dispatch(setIsPaymentInProgress(true));

  if (method === PAYMENT_METHODS.BANK_CARD) {
    const creditCardData = _get(form, `${creditCardConstants.PAY_FORM}.values`);

    const preAuthParams = {
      paySystem: tag,
      returnUrl: getReturnUrl(surchargeId, 'hotels'),
      testPg: false, // don`t skip pre-auth
      browserData: preAuthHelpers.getDefaultBrowserData(),
      ...prepareCardForPreAuth(creditCardData),
    };

    localStorage.set(SAVED_STATE_LS_KEY, {
      paymentGates,
      currentGate,
    });
    /**
     * Сохраняем состояние, чтобы восстановить его после ACS
     */

    const shouldSkipPreAuth = false;
    const preAuthUrl = `/_hotels_order/public/extraCharge/pre-auth/${surchargeId}`;

    dispatch(preAuthActions.initPreAuth3DS(preAuthParams, shouldSkipPreAuth, preAuthUrl));
  } else {
    const payActionCreator = getPayActionCreator('hotels');
    dispatch(payActionCreator());
  }
};

export default startPayment;
