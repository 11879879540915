import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { useOffersData } from './hooks/useOffersData';
import OfferCard from './OfferCard';
import styles from './NewOffersSlider.scss';
import { useLayoutContext } from '@ott/layout-context';
import ArrowButton from './ui/ArrowButton';
import MockOffers from './MockOffers';
import useEmblaCarousel from 'embla-carousel-react';
import { usePrevNextButtons } from './hooks/usePrevNextButtons';
import { useDotButton } from './hooks/useDotButton';
import cls from 'classnames';
import metrics from 'utility/metrics';

const NewOffersSlider = ({ product }: { product: string }) => {
  const { isMobile, viewportWidth } = useLayoutContext();
  const sliderAlign = viewportWidth < 1280 ? 'center' : 'start';
  const { data = [], isLoading } = useOffersData({ product });
  const [emblaRef, emblaApi] = useEmblaCarousel({ align: sliderAlign });

  useEffect(() => {
    if (data.length) {
      metrics('main_offers_show', {
        offers: data.map((offerData) => offerData.id).join(','),
      });
    }
  }, [data]);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi);

  return (
    <div className={styles.offersBlock} data-locator="OffersSlider">
      {data && data.length && !isLoading ? (
        <div className={styles.slider}>
          <div className={styles.cardsViewport} ref={emblaRef}>
            <ul className={styles.cardsContainer}>
              {data.map((offerItem, i) => (
                <li data-locator="OfferCard" key={i.toString()} className={styles.cardsItem}>
                  <OfferCard data-locator={`OfferCardItem_${i}`} data={offerItem} />
                </li>
              ))}
            </ul>
          </div>
          {!prevBtnDisabled && (
            <ArrowButton
              direction="left"
              onClick={onPrevButtonClick}
              data-locator="leftArrowButton"
            />
          )}
          {!nextBtnDisabled && (
            <ArrowButton
              direction="right"
              onClick={onNextButtonClick}
              data-locator="rightArrowButton"
            />
          )}
          <div className={styles.dotsContainer}>
            {scrollSnaps.map((_, i) => (
              <button
                key={i}
                onClick={() => onDotButtonClick(i)}
                className={cls(styles.dot, { [styles.activeDot]: i === selectedIndex })}
                data-locator="dot"
              />
            ))}
          </div>
        </div>
      ) : (
        <MockOffers isMobile={isMobile} />
      )}
    </div>
  );
};

export default hot(module)(NewOffersSlider);
