import { ERRORS, OLD_3DS, SURCHARGE_STATUSES } from 'src/components/pages/SurchargePage/constants';
import getPayStatus from './getPayStatus';
import * as T from '../../types';

const timeout = 1000;

const tryAgainInTimeout = (surchargeId) => {
  return new Promise((resolve) =>
    setTimeout(() => resolve(createPayPolling(surchargeId)), timeout)
  );
};

const createPayPolling = async (surchargeId) => {
  try {
    const { body } = await getPayStatus(surchargeId);

    if (!body) {
      return tryAgainInTimeout(surchargeId);
    }

    const { result, error } = body;

    if (error) {
      return Promise.reject(ERRORS.NETWORK);
    }

    const { status } = result;

    if ([SURCHARGE_STATUSES.FAIL_BLOCK, SURCHARGE_STATUSES.FAIL].includes(status)) {
      return Promise.reject(ERRORS.FAIL_ON_PAY);
    }

    if ([SURCHARGE_STATUSES.WAITING, SURCHARGE_STATUSES.BLOCK].includes(status)) {
      return tryAgainInTimeout(surchargeId);
    }

    if (status === SURCHARGE_STATUSES.CANCELLED) {
      return Promise.reject(ERRORS.ORDER_CHANGE_ERROR);
    }

    if (status === SURCHARGE_STATUSES.FAIL_CANCEL) {
      return Promise.reject(ERRORS.MONEY_BLOCK_CANCELLATION_ERROR);
    }

    if (status === SURCHARGE_STATUSES.THREE_DS) {
      const { postParams, version } = result;

      return {
        postParams,
        is3ds: true,
        isOld3DS: version === OLD_3DS,
      };
    }

    if (status === SURCHARGE_STATUSES.BRIDGER) {
      const { postParams } = result;

      return {
        postParams,
        isBridgerPay: true,
      };
    }

    return {
      status,
    };
  } catch (e) {
    return Promise.reject(ERRORS.NETWORK);
  }
};

export default (surchargeId) => ({
  promise: () => createPayPolling(surchargeId),
  types: [T.PAY_POLLING, T.PAY_POLLING_SUCCESS, T.PAY_POLLING_FAIL],
});
