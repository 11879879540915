export const FETCH_SURCHARGE_DATA = 'ott/index/surcharge/FETCH_SURCHARGE_DATA';
export const FETCH_SURCHARGE_DATA_SUCCESS = 'ott/index/surcharge/FETCH_SURCHARGE_DATA_SUCCESS';
export const FETCH_SURCHARGE_DATA_FAIL = 'ott/index/surcharge/FETCH_SURCHARGE_DATA_FAIL';
export const FETCH_SURCHARGE_DATA_RESET = 'ott/index/surcharge/FETCH_SURCHARGE_DATA_RESET';

export const PAY_REQUEST = 'ott/index/surcharge/PAY_REQUEST';
export const PAY_REQUEST_SUCCESS = 'ott/index/surcharge/PAY_REQUEST_SUCCESS';
export const PAY_REQUEST_FAIL = 'ott/index/surcharge/PAY_REQUEST_FAIL';
export const PAY_REQUEST_RESET = 'ott/index/surcharge/PAY_REQUEST_RESET';

export const PAY_POLLING = 'ott/index/surcharge/PAY_POLLING';
export const PAY_POLLING_SUCCESS = 'ott/index/surcharge/PAY_POLLING_SUCCESS';
export const PAY_POLLING_FAIL = 'ott/index/surcharge/PAY_POLLING_FAIL';
export const PAY_POLLING_RESET = 'ott/index/surcharge/PAY_POLLING_RESET';

export const SET_PAYMENT_GATES = 'ott/index/surcharge/SET_PAYMENT_GATES';
export const SET_CURRENT_PAYMENT_GATE = 'ott/index/surcharge/SET_CURRENT_PAYMENT_GATE';

export const SET_SURCHARGE_ID = 'ott/index/surcharge/SET_SURCHARGE_ID';

export const SET_IS_PAYMENT_IN_PROGRESS = 'ott/index/surcharge/SET_IS_PAYMENT_IN_PROGRESS';
