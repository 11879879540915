import { PRODUCTS } from 'src/components/pages/SurchargePage/constants';

const getReturnUrl = (surchargeId, product) => {
  const {
    location: { protocol, host },
  } = window;

  switch (product) {
    case PRODUCTS.HOTELS:
      return `${protocol}//${host}/_hotels_order/public/extraCharge/continue3ds/${surchargeId}`;

    default:
      return '';
  }
};

export default getReturnUrl;
