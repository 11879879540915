import React from 'react';
import SuccessIcon from '@ott/icon-success';
import Button from '@ott/button';
import { lang, l10n, l10nhtml } from '@ott/l10n';
import styles from './SuccessState.scss';

const SuccessState = () => {
  return (
    <div className={styles.SuccessState}>
      <div className={styles.icon}>
        <SuccessIcon />
      </div>
      <h1 className={styles.title}>{l10n('surcharge.success.title')}</h1>
      <p className={styles.description}>{l10nhtml('surcharge.success.description')}</p>
      <Button href={`/${lang}/`}>{l10n('surcharge.success.newTrip')}</Button>
    </div>
  );
};

export default SuccessState;
