import React from 'react';
import cls from 'classnames';
import OTTLogo from '@ott/icon-ott-logo';
import styles from './Header.scss';

const Header = ({ className, config }) => {
  const isPartnerPage = Boolean(config?.partner);

  if (isPartnerPage) {
    const { partner } = config;
    const partnerLogo = partner?.logo?.mobile;
    /**
     * Берем mobile потому что такие логотипы предназанчены для
     * светлого фона. У нас именно такой
     */

    return (
      <header className={cls(className, styles.HeaderPartner)}>
        <div className={styles.header}>
          <OTTLogo />
          <div className={styles.divider}></div>
          <img draggable={false} className={styles.logo} src={partnerLogo} />
        </div>
      </header>
    );
  }

  return (
    <header className={cls(className, styles.Header)}>
      <OTTLogo />
    </header>
  );
};

export default Header;
