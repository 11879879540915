import superagent from '@ott/superagent';
import T from './../types';
import { OFFERS_TYPES } from 'constants/offers';

export default function generateAdditionalData(offerId, offerType) {
  return function (dispatch) {
    return dispatch({
      offerId,
      promise: async () => {
        const res = await superagent
          .get(`/_mark/offers/${offerId}`)
          .query({ additionalData: true })
          .set('Accept', 'application/json');

        if (res && res.body && res.body.result) {
          return { offerId, result: getDataByType(res.body.result, offerType) };
        } else {
          return Promise.reject({ ...res.body.error, offerId });
        }
      },
      types: [
        T.ADDITIONAL_DATA_FETCH_INVOKE,
        T.ADDITIONAL_DATA_FETCH_SUCCESS,
        T.ADDITIONAL_DATA_FETCH_FAILURE,
      ],
    });
  };
}

export function getDataByType(data, type) {
  switch (type) {
    case OFFERS_TYPES.referral_promo:
    case OFFERS_TYPES.personal_promo:
      return data.promo;
    case OFFERS_TYPES.referral_link:
    case OFFERS_TYPES.dynamic_link:
      return data.buttonLink;
  }

  return null;
}
