import React from 'react';
import s from './SeoOptions.scss';
import cls from 'classnames';

import { Container, Row, Col } from '@ott/grid';
import ChevronRightIcon from '@ott/icon-small-chevron-right';
import { l10n } from '@ott/l10n';

const SeoOptions = ({ cabinet, app }) => {
  return (
    <Container data-locator="seo-options" className={s.seoOptions}>
      <Row>
        <Col desktop={6} tablet={6} mobile={4}>
          <div className={s.block}>
            <div className={s.caption}>
              <h2 className={s.title}>{l10n('page404.component.cabinet.title')}</h2>
              <div>
                <div className={cls(s.icon, s.seoCabinet)} />
              </div>
            </div>
            <a className={s.link} href={cabinet.link.href}>
              {l10n('page404.component.cabinet.link.title')}{' '}
              <ChevronRightIcon className={s.arrow} />
            </a>
          </div>
        </Col>

        <Col desktop={6} tablet={6} mobile={4}>
          <div className={s.block}>
            <div className={s.caption}>
              <h2 className={s.title}>{l10n('page404.component.app.title')}</h2>
              <div>
                <div className={cls(s.icon, s.seoApp)} />
              </div>
            </div>
            <a className={s.link} href={app.link.href}>
              {l10n('page404.component.app.link.title')} <ChevronRightIcon className={s.arrow} />
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SeoOptions;
