import * as T from './types';

const initialState = {
  isOpen: false,
  data: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case T.CUSTOM_POPUP_OPEN: {
      return {
        isOpen: true,
        data: action.data,
      };
    }
    case T.CUSTOM_POPUP_CLOSE: {
      return {
        isOpen: false,
        data: null,
      };
    }
    default: {
      return state;
    }
  }
}
