import { useSelector } from 'react-redux';

const useUpsell = (): { upsellAvailable: boolean, expiredDate?: Date } => {
  const { data: hotelsConfigData } = useSelector((state) => state.hotels.hotelsConfig || {});
  const { until } = hotelsConfigData?.upsell ?? {};
  const expiredDate = until ? new Date(until * 1000) : undefined;
  const upsellAvailable = Boolean(expiredDate) && (expiredDate as Date) > new Date();

  return { upsellAvailable, expiredDate };
};

export default useUpsell;
