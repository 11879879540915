import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { setIsPaymentInProgress } from 'src/redux/modules/surcharge/actions';

const get3dsParams = ({ isOld3DS, postParams, termUrl }) => {
  if (isOld3DS) {
    return {
      PaReq: postParams.PaReq,
      MD: postParams.MD,
      TermUrl: termUrl,
      // в документации к 3ds PascalCase
    };
  }
  return {
    creq: postParams.CReq,
    threeDSSessionData: postParams.ThreeDSSessionData,
  };
};

export const Form3ds = React.memo(({ postParams, isOld3DS, termUrl }) => {
  const { ACSUrl } = postParams;
  const formRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (formRef.current) {
      /**
       * Если банк затупит, то форма будет отправляться очень долго,
       * поэтому показываем лоадер
       */
      dispatch(setIsPaymentInProgress(true));
      formRef.current.submit();
    }
  }, [formRef]);

  const params = get3dsParams({ isOld3DS, postParams, termUrl });

  return (
    <form style={{ display: 'none' }} action={ACSUrl} method="post" ref={formRef}>
      {(Object.entries(params) || []).map(([key, value]) => (
        <input key={key} type="hidden" name={key} value={value} />
      ))}
    </form>
  );
});

Form3ds.propTypes = {
  postParams: PropTypes.oneOf([
    PropTypes.shape({
      PaReq: PropTypes.string,
      MD: PropTypes.string,
      ACSUrl: PropTypes.string,
    }),
    PropTypes.shape({
      CReq: PropTypes.string,
      ThreeDSSessionData: PropTypes.string,
    }),
  ]).isRequired,
  isOld3DS: PropTypes.bool.isRequired,
  termUrl: PropTypes.string.isRequired,
};
