import React from 'react';

type PromoIconProps = {
  className?: string;
  w?: number;
  h?: number;
};

/**
 * Инлайн SVG для маркетинговой акции.
 * Обычно временно заменяется иконка трипкоина возле раздела "Отели" на новую иконку распродажи.
 * Последнее актуальное промо - {@link https://jira.twiket.com/browse/FI-1038|Задача}
 */
const PromoIcon = ({ className = '', w = 12, h = 12 }: PromoIconProps) => (
  <svg
    className={className}
    width={w}
    height={h}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 12"
  >
    <circle cx="6" cy="6" r="6" fill="#f45992" />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M8.82 6.893C8.82 8.591 7.519 9.6 5.91 9.6 4.303 9.6 3 8.591 3 6.893c0-1 .507-1.667 1.01-2.328.58-.764 1.153-1.518.93-2.765 1.456.243 2.426 2.096 2.018 3.26 0 0 .699-.117.65-1.077a4.204 4.204 0 0 1 1.213 2.91Zm-4.553.053a.764.764 0 0 0 .564.219c.23 0 .418-.073.564-.219.146-.145.219-.346.219-.6a.908.908 0 0 0-.101-.442.682.682 0 0 0-.282-.282.82.82 0 0 0-.4-.097.844.844 0 0 0-.404.097.69.69 0 0 0-.278.282.908.908 0 0 0-.101.441c0 .255.073.456.219.601Zm3.005-1.379h-.711L4.549 8.51h.711l2.012-2.943Zm-2.31 1.06c-.034.056-.078.084-.131.084-.054 0-.099-.028-.135-.084a.592.592 0 0 1-.05-.282c0-.129.016-.221.05-.277.037-.06.081-.089.135-.089.053 0 .097.03.13.089.037.056.055.148.055.277a.553.553 0 0 1-.055.282Zm1.464 1.707a.764.764 0 0 0 .564.218c.23 0 .418-.073.564-.218.146-.146.22-.347.22-.602a.908.908 0 0 0-.102-.441.683.683 0 0 0-.282-.282.82.82 0 0 0-.4-.096.844.844 0 0 0-.404.096.69.69 0 0 0-.278.282.908.908 0 0 0-.1.441c0 .255.072.456.218.602Zm.695-.32c-.034.056-.078.084-.13.084-.054 0-.1-.028-.136-.084a.592.592 0 0 1-.05-.282c0-.128.017-.221.05-.277.037-.059.082-.088.135-.088.053 0 .097.03.13.088.037.056.055.149.055.277a.553.553 0 0 1-.054.282Z"
      clipRule="evenodd"
    />
  </svg>
);

export default PromoIcon;
