import getFP from 'lodash/fp/get';
import { success } from '@ott/utility-loader';

import { OFFERS_SOURCE } from 'constants/offers';
import { checkPermissions } from 'helpers/offers';

export const getOffers = getFP('offers');
export const getAdditionalOffersData = getFP('offers.additionalData');
export const getRemoteOffersAuthorized = getFP('offers.items.data.buyerId');

export const offersAdapter = (state, props) => {
  const { source, items } = props;

  const {
    common: { auth },
  } = state;

  let offerItems = [];
  let offersStatus = {};

  switch (source) {
    case OFFERS_SOURCE.REMOTE:
      const {
        items: { data: { offers } = {}, ...restItemsData } = {},
        ...restOffersData
      } = getOffers(state);

      if (offers) {
        offerItems = [
          ...offers.map(offer => ({
            itemData: { ...offer },
            itemMeta: {
              behavior: [],
            },
          })),
        ];
      } else {
        offerItems = [];
      }

      offersStatus = { items: { ...restItemsData }, ...restOffersData };
      break;

    case OFFERS_SOURCE.LOCAL:
    default:
      offerItems = [
        ...items.filter(item => checkPermissions(item.permissions, auth.data)).map(offer => {
          return {
            itemData: {
              buttonLink: offer.link.href,
              buttonText: offer.footerCaption,
              description: offer.text,
              title: offer.link.title,
              icon: offer.backgroundImageType === 'icon' ? offer.backgroundImage : '',
              image: offer.backgroundImageType === 'cover' ? offer.backgroundImage : '',
              name: offer.name,
              id: offer.key,
              specificLocators: offer.specificLocators,
            },
            itemMeta: {
              cardColor: offer.backgroundColor,
              titleColor: offer.titleColor,
              textColor: offer.textColor,
              footerTextColor: offer.footerColor,
              isBig: typeof offer.isBig !== 'undefined' ? offer.isBig : false,
              behavior: offer.link.behavior,
              behaviorData: offer.behaviorData,
              metricsKM: offer.metricsKM,
            },
          };
        }),
      ];
      offersStatus = { items: { ...success() } };
      break;
  }

  return {
    items: [...offerItems],
    status: { ...offersStatus },
  };
};
