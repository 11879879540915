import React, { useState, useEffect, useRef } from 'react';
import cls from 'classnames';

import { Container } from '@ott/grid';
import { useLayoutContext } from '@ott/layout-context';
import RadioButtonGroup from '@ott/radio-button-group';

import styles from './HotelsSeoLinks.scss';

type Props = {
  title: string;
  options: {
    title: string;
    links: { title: string; href: string }[];
  }[];
  className?: string;
};

const ONE_ROW_HEIGHT = 56;
const TWO_ROWS_HEIGHT = 112;

const HotelsSeoLinks = ({ title, options, className }: Props) => {
  const [selectedOptionId, setSelectedOptionId] = useState(0);
  const [maxHeight, setMaxHeight] = useState(0);
  const [currentHeight, setCurrentHeight] = useState(TWO_ROWS_HEIGHT);
  const { isMobile } = useLayoutContext();

  const linksRefs = useRef<(HTMLDivElement | null)[]>([]);

  const items = options.map((option) => {
    return { value: option.title, name: option.title };
  });

  const selectedOptionChange = (value: string) => {
    setSelectedOptionId(items.findIndex((item) => item.name === value));
    setCurrentHeight(TWO_ROWS_HEIGHT);
  };

  const onExpandButtonClick = () => {
    if (selectedOptionId === 3) {
      setCurrentHeight(currentHeight + ONE_ROW_HEIGHT);
    } else {
      setCurrentHeight(currentHeight + TWO_ROWS_HEIGHT);
    }
  };

  const onMinimizeButtonClick = () => {
    setCurrentHeight(TWO_ROWS_HEIGHT);
  };

  useEffect(() => {
    const maxHeight = linksRefs.current[selectedOptionId]?.scrollHeight;
    setMaxHeight(maxHeight);
  }, [selectedOptionId]);

  return (
    <Container className={className} data-locator="hotels-seo-links">
      <h1 className={styles.title}>{title}</h1>
      <div className={styles.radioButtonsWrapper}>
        <RadioButtonGroup
          defaultIndex={0}
          stretched={isMobile}
          optionClassName={styles.radioButtonOption}
          options={items}
          onChange={selectedOptionChange}
        />
      </div>

      {options.map((option, index) => (
        <div
          ref={(element) => {
            linksRefs.current[index] = element;
          }}
          key={option.title}
          className={cls(styles.links, {
            [styles.linksHidden]: selectedOptionId !== index,
          })}
          style={{ height: `${currentHeight}px` }}
        >
          {option.links.map((link) => (
            <a
              key={link.href}
              className={styles.link}
              href={link.href}
              target="_blank"
              rel="noreferrer"
            >
              {link.title}
            </a>
          ))}
        </div>
      ))}

      {currentHeight >= maxHeight ? (
        <button
          type="button"
          className={cls(styles.minimizeButton, {
            [styles.minimizeButtonPadding]: currentHeight >= maxHeight,
            [styles.minimizeButtonHidden]: currentHeight === maxHeight,
          })}
          onClick={onMinimizeButtonClick}
        >
          Cвернуть
        </button>
      ) : (
        <button type="button" className={styles.expandButton} onClick={onExpandButtonClick}>
          Показать ещё
        </button>
      )}
    </Container>
  );
};

export default HotelsSeoLinks;
