import React from 'react';
import styles from './OfferWrapper.scss';
import { OfferWrapperProps } from '../../types';
import { getCardPropsFromOfferData } from './helpers';
import { useDispatch } from 'react-redux';
import { showAuth } from 'src/redux/modules/common/authModal/actions';
import OfferCardAction from '../OfferCardAction';
import BaseOfferCard from '../BaseOfferCard';

const AuthWrapper = ({ data, onClick, children, className, ...props }: OfferWrapperProps) => {
  const dispatch = useDispatch();
  const cardProps = getCardPropsFromOfferData(data);

  const handleAuthClick = () => {
    if (onClick) {
      onClick();
    }
    dispatch(showAuth());
  };

  return (
    <button type="button" onClick={handleAuthClick} className={styles.container} {...props}>
      <BaseOfferCard
        {...cardProps}
        actionNode={
          <OfferCardAction className={styles.cardAction}>{data.buttonText}</OfferCardAction>
        }
      />
    </button>
  );
};

export default AuthWrapper;
