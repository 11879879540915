import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { parse } from 'qs';

const useQuery = () => {
  const { search } = useLocation();

  const params = useMemo(() => {
    if (!search) {
      return {};
    }

    return parse(search.substring(1));
  }, [search]);
  return params;
};

export default useQuery;
