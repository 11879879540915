import React from 'react';
import { useDispatch } from 'react-redux';

import { l10n } from '@ott/l10n';

import { prepareActivityLink, prepareSearchLink } from 'utility/activitiesLinks';
import { showAuth } from 'src/redux/modules/common/authModal/actions';

import { Container } from '@ott/grid';
import ActivitiesWidget from '@ott/activities-widget';

import styles from './ActivitiesInPopularCities.scss';

const ActivitiesInPopularCities = ({ cities }) => {
  const dispatch = useDispatch();

  const openAuth = () => dispatch(showAuth());

  return (
    <Container className={styles.container}>
      <div className={styles.title}>{l10n('activities.titles.activitiesInPopularCities')}</div>

      {cities.map(({ localityId, byInterest, l10nTitlePath, subtitleActivitiesTemplate }, i) => (
        <ActivitiesWidget
          className={styles.widget}
          key={localityId}
          trackPage="activities-index"
          trackId={`popular-city-${i + 1}`}
          openAuth={openAuth}
          prepareCustomActivityLink={prepareActivityLink}
          prepareCustomSearchLink={prepareSearchLink}
          searchParams={{
            localityId,
            byInterest,
          }}
          shouldUseExternalLinks={true}
          specificLocators="activities-in-popular-city"
          subtitleActivitiesTemplate={subtitleActivitiesTemplate}
          title={l10n(l10nTitlePath)}
        />
      ))}
    </Container>
  );
};

export default ActivitiesInPopularCities;
